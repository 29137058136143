.createProject {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  &-header {
    position: sticky;
    top: 0;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
    padding: 1rem;
    border-radius: $radius;
    background-color: $white;

    &--title {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      &__back {
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        border-radius: calc($radius/1.2);
        transition: all 0.3s ease-in-out;
        &:active {
          opacity: 0.8;
        }
        &:hover {
          background-color: rgba($color: $black, $alpha: 0.07);
        }
      }
      &__name {
        font-weight: 400;
        color: rgba($color: $primary, $alpha: 1);
        text-transform: uppercase;
      }
    }
    &--action {
      display: flex;
      column-gap: 0.5rem;

      select {
        text-transform: uppercase;
      }
      &__source{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 .5rem;
      }
    }
  }
  &-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 2rem;

    .loan {
      position: sticky;
      top: 10%;
      border-radius: $radius;
      overflow: hidden;
      width: 23%;
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
      &-heading {
        background-color: $primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.7rem;
        border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.12);
        &--title {
          font-weight: 300;
          color: $white;
          text-transform: uppercase;
          font-size: 1.5rem;
        }
        &--action {
          display: flex;
          column-gap: 0.5rem;

          select {
            text-transform: uppercase;
          }
          &__add {
            cursor: pointer;
            width: 3rem;
            height: 3rem;
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary;
            background-color: $white;
            border-radius: calc($radius/1.2);
            transition: all 0.3s ease-in-out;
            &:active {
              opacity: 0.8;
            }
          }
        }
      }
      &-list {
        &--item.active {
          span {
            background-color: $alphaPrimary;
          }
          * {
            color: $primary;
          }
          .fa-light {
            color: $error;
          }
          &:last-child {
            border-radius: 0 0 $radius $radius;
          }
        }
        &--item.error {
          span {
            background-color: $alphaError;
          }
        }
        &--item.editMode:hover {
          span {
            transform: translateX(-15%);
          }
        }
        &--item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          position: relative;
          cursor: pointer;
          width: 100% !important;
          border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.07);

          &:last-child {
            border-bottom: none;
          }

          &__name {
            padding: 1.5rem;
            font-weight: 300;
            font-size: 1.3rem;
            text-transform: uppercase;
            background-color: $white;
            position: relative;
            width: 100% !important;
            z-index: 1;
            transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          &__delete {
            position: absolute;
            right: 0;
            z-index: 0;
            cursor: pointer;
            width: 15%;
            height: 100% !important;
            font-size: 1.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $error;
            transition: all 0.3s ease-in-out;
            background-color: rgba($color: $error, $alpha: 0.5);
            &:active {
              opacity: 0.8;
            }
          }
        }
      }
    }
    .editor {
      padding: 1rem;
      border-radius: $radius;
      width: 77%;
      background-color: $white;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;

      display: flex;
      flex-direction: column;
      row-gap: 1rem;
    }
  }
}
