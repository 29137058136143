.createLoan {
  background-color: rgba($color: $primary, $alpha: 0.05);
  border-radius: $radius;

  &-header {
    border-radius: $radius;
    background-color: $primary;
    padding: 1rem;
    &__name {
      font-weight: 500;
      font-size: 1.5rem;
      display: flex;
      column-gap: 0.5rem;
      text-transform: uppercase;
      color: $white;
    }
  }
  &-body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    &--relative {
      position: relative;
      z-index: 1;
    }
    &--wrapper {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
    &--heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      &__name {
        font-weight: 500;
        font-size: 1.5rem;
        display: flex;
        column-gap: 0.5rem;
        text-transform: uppercase;
        color: $primary;
      }
    }
  }
}
