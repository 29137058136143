.topbar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
  align-items: flex-end;
  min-height: 5.25rem;
  border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.1);
  &-back {
    cursor: pointer;
    padding-right: 1rem;
  }
  &-title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &--head {
      font-size: 2.5rem;
      font-weight: 300;
      // letter-spacing: 0.2rem;
      color: $primary;
      text-transform: uppercase;
    }
    &--body {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;
      color: $alphaBlack;
      span {
        font-weight: 300;
        font-size: 1.4rem;
        text-transform: uppercase;
      }
      i {
        font-size: 2rem;
      }
    }
  }
}
