@import "./AddAccountChief/addaccountchief";
.accountchief {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-main {
    display: flex;
    justify-content: center;
    gap: 2rem;
    &--list {
      flex: 0.275;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
      border-radius: 0.4rem;
      height: max-content;
      overflow: hidden;
      &__title {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 300;
        letter-spacing: 0.02em;
        background-color: $primary;
        border-top-right-radius: 0.4rem;
        border-top-left-radius: 0.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        & > * {
          padding: 0.5rem;
        }
        &-action {
          background-color: #fff;
          color: $primary;
          height: 80%;
          font-size: 1.75rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 0.4rem;
        }
      }
    }
    &--description {
      flex: 1;
      border-radius: 0.4rem;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
    }
  }
}
