.subprojectcreate {
  width: 50vw !important;
  max-height: 80vh;
  overflow: auto;
  padding: 0.2rem;
  &-heading {
    color: $primary;
    margin: 1rem 0;
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
    row-gap: 1rem;
  }
  &-loans {
    display: flex;
    column-gap: 2.2%;
    row-gap: 2rem;
    flex-wrap: wrap;
    &--item {
      width: 23.3%;
      border-radius: $radius;
      padding: 1rem;
      cursor: pointer;
      transition: all 0.3s linear;
      border-left: 0.1rem solid rgba($color: $black, $alpha: 0.15);
      border-right: 0.1rem solid rgba($color: $black, $alpha: 0.15);
      border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.15);
      border-top: 0.5rem solid rgba($color: $black, $alpha: 0.15);

      &_date {
        font-size: 1.2rem;
        color: $alphaBlack;
      }
      &_agency {
        color: $primary;
        font-size: 1.5rem;
        text-transform: uppercase;
      }
      &_currency {
        font-size: 1.2rem;
        color: $black;
      }
      &_selected {
        border-top: 0.5rem solid $primary;
        border-left: 0.1rem solid $primary;
        border-right: 0.1rem solid $primary;
        border-bottom: 0.1rem solid $primary;
      }
    }
  }
}
