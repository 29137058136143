.checkbox {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 0;
  &-label {
    font-size: 1.4rem;
    color: $primary;
    font-weight: 300;
    text-transform: uppercase;
  }
  &-input {
    width: 2rem;
    height: 2rem;
    padding: 1rem;
    outline: none;
    border: none;
    border: 1px solid $border;
    border-radius: calc($radius/2);
    &:focus {
      border: 1px solid $primary;
    }
  }
}
