.statusbar {
  width: max-content;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  border-radius: calc($radius * 3);
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  &-active {
    background-color: $alphaSuccess;
    color: $success;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      background-color: $success;
      border-radius: 50%;
    }
  }
  &-inactive {
    background-color: $alphaSecondary;
    color: $secondary;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      background-color: $secondary;
      border-radius: 50%;
    }
  }
  &-completed {
    background-color: $alphaSuccess;
    color: $success;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      background-color: $success;
      border-radius: 50%;
    }
  }
  &-running {
    background-color: $alphaWarning;
    color: $warning;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 8%;
      transform: translateY(-50%);
      width: 0.8rem;
      height: 0.8rem;
      background-color: $warning;
      border-radius: 50%;
    }
  }
}
