.contractor {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-lcdetails {
    width: 40vw;
    max-height: 80vh;
    overflow: auto;
    padding: 0rem 0.25rem;
    &--grid {
      column-gap: 2rem;
      &__item {
        &:nth-child(2n) {
          background-color: rgba($color: $alphaPrimary, $alpha: 0.4);
        }
        display: flex;
        gap: 1rem;
        padding: 1rem;
        font-weight: 300;

        &-title {
          font-size: 2rem;
          font-weight: 400;
          color: $primary;
          text-transform: uppercase;
          padding-bottom: 2rem;
        }
        &-wrapper {
          display: flex;
          padding: 0 1rem;
        }
        &-key {
          text-transform: capitalize;
          flex: 1;
          font-weight: 400;
          color: rgba($color: $black, $alpha: 0.8);
        }
        &-value {
          color: rgba($color: $black, $alpha: 0.5);
          font-weight: 400;
        }
      }
    }
  }
  &-agreementdetails {
    width: 40vw;
    max-height: 80vh;
    overflow: auto;
    padding: 0rem 0.25rem;
    &--grid {
      column-gap: 2rem;
      &__item {
        &:nth-child(2n) {
          background-color: rgba($color: $alphaPrimary, $alpha: 0.4);
        }
        display: flex;
        gap: 1rem;
        padding: 1rem;
        font-weight: 300;

        &-title {
          font-size: 2rem;
          font-weight: 400;
          color: $primary;
          text-transform: uppercase;
          padding-bottom: 2rem;
        }
        &-wrapper {
          display: flex;
          padding: 0 1rem;
        }
        &-key {
          text-transform: capitalize;
          flex: 1;
          font-weight: 400;
          color: rgba($color: $black, $alpha: 0.8);
        }
        &-value {
          color: rgba($color: $black, $alpha: 0.5);
          font-weight: 400;
        }
      }
    }
  }
  &-perfDetails {
    width: 40vw;
    max-height: 80vh;
    overflow: auto;
    padding: 0rem 0.25rem;
    &--grid {
      column-gap: 2rem;
      &__item {
        &:nth-child(2n) {
          background-color: rgba($color: $alphaPrimary, $alpha: 0.4);
        }
        display: flex;
        gap: 1rem;
        padding: 1rem;
        font-weight: 300;

        &-title {
          font-size: 2rem;
          font-weight: 400;
          color: $primary;
          text-transform: uppercase;
          padding-bottom: 2rem;
        }
        &-wrapper {
          display: flex;
          padding: 0 1rem;
        }
        &-key {
          text-transform: capitalize;
          flex: 1;
          font-weight: 400;
          color: rgba($color: $black, $alpha: 0.8);
        }
        &-value {
          color: rgba($color: $black, $alpha: 0.5);
          font-weight: 400;
        }
      }
    }
  }
  &-descriptionmodal {
    width: clamp(min(70vw, 60rem), 60rem, 100vw);
    height: clamp(min(60vh, 40rem), 40rem, 100vw);
    overflow: auto;
  }
}
