.addloan {
  &-header {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    .select {
      &-select {
        text-transform: uppercase;
      }
    }
  }
  &-main {
    max-height: 80vh;
    min-height: 80vh;
    padding: 0 0;
    width: 80vw;
    overflow: auto;
    margin-top: 0.5rem;
    &--single {
      display: grid;
      grid-column: 1/-1;
    }
    &--submit {
      grid-column: 1/-1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
