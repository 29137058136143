.confirmationmodal {
  min-width: 30vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  &-main {
    font-size: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    &--excl {
      color: $primary;
      font-size: 15rem;
      -webkit-animation: rfwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: rfwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
  }
  &-sub {
    font-size: 1.24rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    letter-spacing: 0.02rem;
    &--static {
      color: $error;
    }
  }
  &-option {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    & > * {
      min-height: 2.25rem;
      min-width: 7rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.7rem 0;
      font-weight: 300;
      cursor: pointer;
      border-radius: $radius;
    }
    &--check {
      color: #fff;
      background-color: $primary;
    }
    &--cross {
      background-color: white;
      color: $error;
      border: solid 1px $error;
      &:hover {
        background-color: $alphaError;
        border: solid 1px $alphaError;
        color: $error;
      }
    }
  }
}

@-webkit-keyframes rfwd {
  0% {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}
@keyframes rfwd {
  0% {
    -webkit-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  100% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}
