@import "./ProfileMenu/profilemenu";
@import "./ChangePassword/changepassword";
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $radius;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
  flex: 1;
  &-burger {
    cursor: pointer;
    &__icon {
      color: $primary;
      font-size: 2.5rem;
    }
  }
  &-action {
    display: flex;
    justify-content: flex-end;
    column-gap: 2rem;
    align-items: center;
    &--language {
      display: flex;
      // gap: .5rem;
      // padding: 0.5rem;
      background-color: $alphaPrimary;
      border-radius: $radius;
      &__en,
      &__np {
        all: unset;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $radius;
        font-weight: 300;
        font-size: 1.2rem;
        cursor: pointer;
        color: $primary;
      }
      &-selected {
        background-color: $primary !important;
        color: $white;
      }
    }

    &--notification {
      cursor: pointer;
      color: $alphaBlack;
      &__icon {
        font-size: 2rem;
      }
    }
    &--profile {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      cursor: pointer;
      position: relative;
      &__user {
        width: 3rem;
        height: 3rem;
        background-color: $primary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $radius;
      }
      &__name {
        text-transform: uppercase;
        color: $alphaBlack;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.language-selected {
  background-color: $primary;
  color: $white;
}
