.loadingmodal {
  top: 0;
  left: 0;
  z-index: 2000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: rgba($color: $alphaPrimary, $alpha: 0.5);
  &-loader {
    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    margin: 5rem auto;
    border: 1rem solid $primary;
    border-left-color: $white;
    animation: load8 1.2s infinite linear;
    &::after {
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
    }
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
