@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

a {
  text-decoration: none;
  color: #000;
}

.notfound {
  border-radius: 0.4rem;
  padding: 3rem 0;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  color: rgba(68, 68, 68, 0.5333333333);
  font-style: italic;
  letter-spacing: -0.1rem;
}
.notfound-bordered {
  border: 1px dashed rgba(142, 142, 142, 0.4666666667);
}

.error-remarks {
  border: 0.1rem dashed #c71c1c;
  border-radius: 0.4rem;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #c71c1c;
  background-color: #f7bbbb;
}

::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem !important;
  background: #c5d9e2;
}

::-webkit-scrollbar-thumb:hover {
  background: #006699;
}

.profilemenu {
  position: absolute;
  top: 150%;
  right: -5%;
  color: #006699;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0.4rem;
  overflow: hidden;
  z-index: 5;
  padding: 0.4rem 0;
  min-height: 5rem;
  min-width: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.profilemenu-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0 1.25rem;
}
.profilemenu-item--name {
  font-weight: 300;
  font-size: 1.4rem;
  padding: 1.2rem;
}
.profilemenu-item > * {
  word-break: keep-all;
}
.profilemenu-item:hover {
  background-color: #c5d9e2;
}
.profilemenu-item:last-child {
  color: #c71c1c;
}
.profilemenu-item:last-child:hover {
  background-color: #f7bbbb;
}

.changepassword {
  min-width: max(30vw, 15rem);
}
.changepassword-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.changepassword-main--submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 0.5rem 0;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.4rem;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
  flex: 1;
}
.nav-burger {
  cursor: pointer;
}
.nav-burger__icon {
  color: #006699;
  font-size: 2.5rem;
}
.nav-action {
  display: flex;
  justify-content: flex-end;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  align-items: center;
}
.nav-action--language {
  display: flex;
  background-color: #c5d9e2;
  border-radius: 0.4rem;
}
.nav-action--language__en, .nav-action--language__np {
  all: unset;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  font-weight: 300;
  font-size: 1.2rem;
  cursor: pointer;
  color: #006699;
}
.nav-action--language-selected {
  background-color: #006699 !important;
  color: #fff;
}
.nav-action--notification {
  cursor: pointer;
  color: rgba(68, 68, 68, 0.5333333333);
}
.nav-action--notification__icon {
  font-size: 2rem;
}
.nav-action--profile {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  cursor: pointer;
  position: relative;
}
.nav-action--profile__user {
  width: 3rem;
  height: 3rem;
  background-color: #006699;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
}
.nav-action--profile__name {
  text-transform: uppercase;
  color: rgba(68, 68, 68, 0.5333333333);
}
.nav-action--profile__name:hover {
  color: #006699;
}

.language-selected {
  background-color: #006699;
  color: #fff;
}

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem 1.5rem;
  position: sticky;
  top: 0;
  width: 19%;
  height: 100vh;
  overflow: auto;
}
.sidebar.between {
  justify-content: space-between;
}
.sidebar.normal {
  gap: 2rem;
}
.sidebar.close {
  width: auto !important;
}
.sidebar.close .sidebar-action__create {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}
.sidebar-logo__image {
  width: 9rem;
  height: 9rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.sidebar-logo__image-small {
  width: 4.5rem !important;
  height: 4.5rem !important;
}
.sidebar-logo__text {
  text-align: center;
  text-transform: uppercase;
  color: #006699;
  font-weight: 700;
}
.sidebar-menu {
  list-style: none;
}
.sidebar-menu--list {
  padding: 0.5rem;
  border-radius: 0.4rem;
  margin: 0.5rem 0;
  text-transform: uppercase;
}
.sidebar-menu--list__link {
  width: 100%;
  color: #000;
  font-weight: 300;
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  font-size: 1.4rem;
}
.sidebar-menu--list__link i {
  color: rgba(68, 68, 68, 0.5333333333);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
}
.sidebar-menu--list__active {
  background-color: #006699;
}
.sidebar-menu--list__active__link {
  color: #fff;
}
.sidebar-menu--list__active__link i {
  color: #006699;
  background-color: #fff;
}
.sidebar-action {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.sidebar-action__create-hover {
  background-color: #fff !important;
  border: 0.1rem solid #006699 !important;
  color: #006699 !important;
}
.sidebar-action__create-hover:hover {
  background-color: #006699 !important;
  color: #fff !important;
}
.sidebar-action__create {
  background-color: #006699;
  color: #fff;
  padding: 1rem;
  border-radius: 0.4rem;
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.topbar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0rem;
  gap: 0.5rem;
  align-items: flex-end;
  min-height: 5.25rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}
.topbar-back {
  cursor: pointer;
  padding-right: 1rem;
}
.topbar-title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.topbar-title--head {
  font-size: 2.5rem;
  font-weight: 300;
  color: #006699;
  text-transform: uppercase;
}
.topbar-title--body {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  color: rgba(68, 68, 68, 0.5333333333);
}
.topbar-title--body span {
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.topbar-title--body i {
  font-size: 2rem;
}

.filesmodal {
  width: 60vw;
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.filesmodal-nodata {
  width: 60vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.filesmodal-main {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.filesmodal-main--item {
  width: 15rem;
  height: 15rem;
  border: 1px solid rgba(0, 102, 153, 0.1098039216);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}
.filesmodal-main--item__wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
.filesmodal-main--item__label {
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filesmodal-main--item__image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.filesmodal-main--item__icon {
  font-size: 10rem;
}
.filesmodal-main--item__edit {
  background-color: rgba(255, 0, 0, 0.521);
  color: white;
  position: absolute;
  right: 0rem;
  top: 0rem;
  width: 4rem;
  height: 4rem;
  display: grid;
  place-items: center;
  display: none;
  cursor: pointer;
}
:hover > .filesmodal-main--item__edit {
  display: grid;
}

.calendar {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
}
.calendar * {
  font-size: 1.4rem;
}
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #006699;
  color: #fff;
}
.calendar-header--close {
  cursor: pointer;
}
.calendar-header--close:active {
  opacity: 0.8;
}
.calendar-controls {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
.calendar-controls--item {
  width: 30%;
}
.calendar-days {
  display: flex;
  justify-content: space-between;
}
.calendar-days--item {
  width: 14.28%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1rem solid rgba(0, 102, 153, 0.06);
  background-color: #c5d9e2;
  color: #006699;
}

.createLoan {
  background-color: rgba(0, 102, 153, 0.05);
  border-radius: 0.4rem;
}
.createLoan-header {
  border-radius: 0.4rem;
  background-color: #006699;
  padding: 1rem;
}
.createLoan-header__name {
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  text-transform: uppercase;
  color: #fff;
}
.createLoan-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.createLoan-body--relative {
  position: relative;
  z-index: 1;
}
.createLoan-body--wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.createLoan-body--heading {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.createLoan-body--heading__name {
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  text-transform: uppercase;
  color: #006699;
}

.confirmationmodal {
  min-width: 30vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}
.confirmationmodal-main {
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.confirmationmodal-main--excl {
  color: #006699;
  font-size: 15rem;
  animation: rfwd 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.confirmationmodal-sub {
  font-size: 1.24rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  letter-spacing: 0.02rem;
}
.confirmationmodal-sub--static {
  color: #c71c1c;
}
.confirmationmodal-option {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.confirmationmodal-option > * {
  min-height: 2.25rem;
  min-width: 7rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 0;
  font-weight: 300;
  cursor: pointer;
  border-radius: 0.4rem;
}
.confirmationmodal-option--check {
  color: #fff;
  background-color: #006699;
}
.confirmationmodal-option--cross {
  background-color: white;
  color: #c71c1c;
  border: solid 1px #c71c1c;
}
.confirmationmodal-option--cross:hover {
  background-color: #f7bbbb;
  border: solid 1px #f7bbbb;
  color: #c71c1c;
}
@keyframes rfwd {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0);
  }
}
.contractormodal {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  align-items: center;
  height: 87vh;
  width: 80vw;
  overflow: auto;
  gap: 1rem;
  padding: 0 1rem;
  background-color: rgba(197, 217, 226, 0.05);
}
.contractormodal-service {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  color: #006699;
  text-transform: uppercase;
}
.contractormodal-service--main {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
  gap: 1rem;
}
.contractormodal-retention {
  grid-column: 1/-4;
}
.contractormodal-performance, .contractormodal-lc, .contractormodal-title {
  grid-column: 1/-1;
  font-weight: 500;
  font-size: 1.5rem;
  color: #006699;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  gap: 0.2rem;
  row-gap: 1rem;
}
.contractormodal-performance--main, .contractormodal-lc--main, .contractormodal-title--main {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  gap: 1rem;
}
.contractormodal-image {
  grid-column: 1/-1;
}
.contractormodal-submit {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  padding: 0.25rem 0;
}
.contractormodal-paymentterm {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
  grid-column: 1/-1;
}
.contractormodal-remarks {
  grid-column: 1/-1;
  border: 0.1rem dashed #c71c1c;
  border-radius: 0.4rem;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #c71c1c;
  background-color: #f7bbbb;
}

.billdetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.billdetails-lcdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
}
.billdetails-lcdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.billdetails-lcdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem 1.5rem 1rem 1.25rem;
  font-weight: 300;
}
.billdetails-lcdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.billdetails-lcdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.billdetails-lcdetails--grid__item-wrapper {
  display: flex;
}
.billdetails-lcdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.billdetails-lcdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.billdetails-agreementdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.billdetails-agreementdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.billdetails-agreementdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.billdetails-agreementdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.billdetails-agreementdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.billdetails-agreementdetails--grid__item-wrapper {
  display: flex;
}
.billdetails-agreementdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.billdetails-agreementdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.billdetails-perfDetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.billdetails-perfDetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.billdetails-perfDetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.billdetails-perfDetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.billdetails-perfDetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.billdetails-perfDetails--grid__item-wrapper {
  display: flex;
}
.billdetails-perfDetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.billdetails-perfDetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.billdetails-descriptionmodal {
  width: clamp(min(70vw, 60rem), 60rem, 100vw);
  height: clamp(min(60vh, 40rem), 40rem, 100vw);
  overflow: auto;
}
.billdetails-verifymodal {
  width: 40rem;
  height: -moz-max-content;
  height: max-content;
}
.billdetails-verifymodal--textarea {
  height: 12.5rem;
}
.billdetails-verifymodal--submit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  gap: 1rem;
}
.billdetails-verifymodal--message {
  padding: 1rem 0;
  font-size: 1.3rem;
}
.billdetails-verifymodal--message__warning {
  color: #961616;
}
.billdetails-verifymodal--message__wrn {
  padding: 1rem 0;
}
.billdetails-verifymodal--message__main {
  font-size: 2rem;
}
.billdetails-verifymodal--reject {
  width: 50vw;
}
.billdetails-verifymodal--reject__submit {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.changepassword {
  width: 10rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.changepassword .input {
  margin-bottom: 1rem;
}
.changepassword .button {
  display: flex;
  justify-content: center;
}

.login {
  height: 100vh;
  height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-main {
  width: 60rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.login-main--title {
  display: flex;
  align-items: center;
  gap: 4rem;
}
.login-main--title__logo {
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  overflow: hidden;
}
.login-main--title__logo > * {
  -o-object-fit: cover;
     object-fit: cover;
}
.login-main--title__main {
  flex: 1.05;
  color: #006699;
  text-align: center;
  font-weight: 500;
  line-height: 2.5rem;
  font-size: 2.1rem;
}
.login-main--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.login-main--form__title {
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #006699;
  font-size: 1.5rem;
  font-weight: 600;
}

.createProject {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.createProject-header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
}
.createProject-header--title {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.createProject-header--title__back {
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006699;
  border-radius: 0.3333333333rem;
  transition: all 0.3s ease-in-out;
}
.createProject-header--title__back:active {
  opacity: 0.8;
}
.createProject-header--title__back:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.createProject-header--title__name {
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
}
.createProject-header--action {
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.createProject-header--action select {
  text-transform: uppercase;
}
.createProject-header--action__source {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
}
.createProject-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.createProject-body .loan {
  position: sticky;
  top: 10%;
  border-radius: 0.4rem;
  overflow: hidden;
  width: 23%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}
.createProject-body .loan-heading {
  background-color: #006699;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.12);
}
.createProject-body .loan-heading--title {
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.5rem;
}
.createProject-body .loan-heading--action {
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.createProject-body .loan-heading--action select {
  text-transform: uppercase;
}
.createProject-body .loan-heading--action__add {
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006699;
  background-color: #fff;
  border-radius: 0.3333333333rem;
  transition: all 0.3s ease-in-out;
}
.createProject-body .loan-heading--action__add:active {
  opacity: 0.8;
}
.createProject-body .loan-list--item.active span {
  background-color: #c5d9e2;
}
.createProject-body .loan-list--item.active * {
  color: #006699;
}
.createProject-body .loan-list--item.active .fa-light {
  color: #c71c1c;
}
.createProject-body .loan-list--item.active:last-child {
  border-radius: 0 0 0.4rem 0.4rem;
}
.createProject-body .loan-list--item.error span {
  background-color: #f7bbbb;
}
.createProject-body .loan-list--item.editMode:hover span {
  transform: translateX(-15%);
}
.createProject-body .loan-list--item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 100% !important;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.07);
}
.createProject-body .loan-list--item:last-child {
  border-bottom: none;
}
.createProject-body .loan-list--item__name {
  padding: 1.5rem;
  font-weight: 300;
  font-size: 1.3rem;
  text-transform: uppercase;
  background-color: #fff;
  position: relative;
  width: 100% !important;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.createProject-body .loan-list--item__delete {
  position: absolute;
  right: 0;
  z-index: 0;
  cursor: pointer;
  width: 15%;
  height: 100% !important;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c71c1c;
  transition: all 0.3s ease-in-out;
  background-color: rgba(199, 28, 28, 0.5);
}
.createProject-body .loan-list--item__delete:active {
  opacity: 0.8;
}
.createProject-body .editor {
  padding: 1rem;
  border-radius: 0.4rem;
  width: 77%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.viewproject {
  padding: 0 1rem;
}
.viewproject-main--table {
  padding: 1rem 0;
}

.addloan-header {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.addloan-header .select-select {
  text-transform: uppercase;
}
.addloan-main {
  max-height: 80vh;
  min-height: 80vh;
  padding: 0 0;
  width: 80vw;
  overflow: auto;
  margin-top: 0.5rem;
}
.addloan-main--single {
  display: grid;
  grid-column: 1/-1;
}
.addloan-main--submit {
  grid-column: 1/-1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewloan {
  padding: 0 1rem;
}
.viewloan-main--table {
  padding: 1rem 0;
}
.viewloan-donordetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.viewloan-donordetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.viewloan-donordetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.viewloan-donordetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.viewloan-donordetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.viewloan-donordetails--grid__item-wrapper {
  display: flex;
}
.viewloan-donordetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.viewloan-donordetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.viewloan-fulldetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.viewloan-fulldetails--grid {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.viewloan-fulldetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0rem;
  font-weight: 300;
  border-bottom: 1px solid rgba(197, 217, 226, 0.5);
}
.viewloan-fulldetails--grid__item-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
}
.viewloan-fulldetails--grid__item-desc {
  display: grid;
  gap: 0.5rem;
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
  padding-bottom: 1rem;
}
.viewloan-fulldetails--grid__item-wrapper {
  display: flex;
}
.viewloan-fulldetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 300;
}
.viewloan-fulldetails--grid__item-value {
  padding-left: 1rem;
}

.createnotice {
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.createnotice-add {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.createnotice-create {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editnotice-description {
  grid-column: 1/-1;
}

.descriptionmodal {
  width: clamp(min(70vw, 60rem), 60rem, 100vw);
  height: clamp(min(60vh, 40rem), 40rem, 100vw);
  overflow: auto;
}

.notice {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
}
.notice-main {
  gap: 2rem;
}
.notice-main--number {
  flex: 0.275;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  border-radius: 0.4rem;
  height: -moz-max-content;
  height: max-content;
  overflow: hidden;
}
.notice-main--number__title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  background-color: #006699;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.notice-main--number__title > * {
  padding: 0.5rem;
}
.notice-main--number__title-icon {
  background-color: #fff;
  color: #006699;
  height: 80%;
  font-size: 1.75rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
}
.notice-main--number__description {
  display: flex;
  flex-direction: column;
}
.notice-main--number__description > * {
  font-size: 1.4rem;
  font-weight: 300;
}
.notice-main--number__description > *:not(:last-child) {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.07);
}
.notice-main--description {
  flex: 1;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}
.notice-main--description__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.02em;
  background-color: #006699;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: #fff;
  padding: 0.49rem 1rem;
  font-size: 1.5rem;
  font-weight: 300;
}
.notice-main--description__title-button {
  background-color: #fff;
  background-color: #006699;
}
.notice-main--description__title-action {
  display: flex;
  gap: 1rem;
}
.notice-main--description__title-action--edit {
  background-color: #fff;
  color: #006699;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
  font-size: 1.75rem;
  cursor: pointer;
}
.notice-main--description__main {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  letter-spacing: 0.1em;
}
.notice-main--description__main-update {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}
.notice-main--description__main-show {
  padding: 1rem 0;
}
.notice-main--notice {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.notice-main--notice.active {
  color: #006699;
}
.notice-main--notice > * {
  padding: 1.5rem 1rem;
}
.notice-main--notice__name {
  width: 30rem !important;
}
.notice-main--notice__action {
  background-color: rgba(199, 28, 28, 0.5);
  height: 100%;
  right: 0;
  width: 3.5rem;
  align-items: center;
  justify-content: center;
  display: none;
}
:hover > .notice-main--notice__action {
  display: flex;
}

.addac-main {
  max-height: 80vh;
  padding: 0 1rem;
  width: 60vw;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}
.addac-main--single {
  display: grid;
  grid-column: 1/-1;
}
.addac-main--submit {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.accountchief {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.accountchief-main {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.accountchief-main--list {
  flex: 0.275;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  border-radius: 0.4rem;
  height: -moz-max-content;
  height: max-content;
  overflow: hidden;
}
.accountchief-main--list__title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  background-color: #006699;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.accountchief-main--list__title > * {
  padding: 0.5rem;
}
.accountchief-main--list__title-action {
  background-color: #fff;
  color: #006699;
  height: 80%;
  font-size: 1.75rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
}
.accountchief-main--description {
  flex: 1;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}

.addemployee-main {
  max-height: 90vh;
  padding: 0 1rem;
  width: 60vw;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}
.addemployee-main--single {
  display: grid;
  grid-column: 1/-1;
}
.addemployee-main--submit {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin {
  padding: 0 1rem;
}
.admin-main--table {
  padding: 1rem 0;
}

.addpm-main {
  max-height: 80vh;
  padding: 0 1rem;
  width: 60vw;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}
.addpm-main--single {
  display: grid;
  grid-column: 1/-1;
}
.addpm-main--submit {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.addac-main {
  max-height: 80vh;
  padding: 0 1rem;
  width: 60vw;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 2rem;
}
.addac-main--single {
  display: grid;
  grid-column: 1/-1;
}
.addac-main--submit {
  grid-column: 1/-1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.accountchief {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.accountchief-main {
  display: flex;
  justify-content: center;
  gap: 2rem;
}
.accountchief-main--list {
  flex: 0.275;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  border-radius: 0.4rem;
  height: -moz-max-content;
  height: max-content;
  overflow: hidden;
}
.accountchief-main--list__title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.02em;
  background-color: #006699;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.accountchief-main--list__title > * {
  padding: 0.5rem;
}
.accountchief-main--list__title-action {
  background-color: #fff;
  color: #006699;
  height: 80%;
  font-size: 1.75rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
}
.accountchief-main--description {
  flex: 1;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}

.dashboardpage {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.dashboardpage-main {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.card {
  border-top: 0.4rem solid #006699;
  height: 15rem;
  width: 25rem;
  border-radius: 0.4rem;
  background-color: #fff;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  display: flex;
  position: relative;
  cursor: pointer;
}
.card:hover {
  background-color: #c5d9e2;
}
.card-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}
.card-main--icon {
  font-size: 3rem;
  color: #006699;
}
.card-main--name {
  font-size: 1.25rem;
  font-weight: 300;
  color: #006699;
}
.card-main--number {
  font-size: 10rem;
  font-weight: 900;
  color: rgba(0, 102, 153, 0.7);
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
}

.viewpmsubprojects {
  width: 50vw;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.viewpmsubprojects-fulldetails {
  overflow: auto;
  padding: 0rem 0.25rem;
}
.viewpmsubprojects-fulldetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.viewpmsubprojects-fulldetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem 0rem;
  font-weight: 300;
  border-bottom: 1px solid rgba(197, 217, 226, 0.5);
}
.viewpmsubprojects-fulldetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.viewpmsubprojects-fulldetails--grid__item-wrapper {
  display: flex;
}
.viewpmsubprojects-fulldetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 300;
}
.viewpmsubprojects-fulldetails--grid__item-value {
  padding-left: 1rem;
}
.viewpmsubprojects-submit {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.viewpmsubprojects-submit--approve {
  background-color: #1fb626 !important;
}
.viewpmsubprojects-submit--reject {
  background-color: #c71c1c !important;
}

.viewbillcustomduty {
  width: 70vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0 0.5rem;
}

.viewbilling {
  padding: 0 1rem;
}
.viewbilling-main--table {
  padding: 1rem 0;
}

.viewsubproject {
  padding: 0 1rem;
}
.viewsubproject-main--table {
  padding: 1rem 0;
}

.admincontractor {
  padding: 0 1rem;
}
.admincontractor-lcdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.admincontractor-lcdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.admincontractor-lcdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.admincontractor-lcdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.admincontractor-lcdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.admincontractor-lcdetails--grid__item-wrapper {
  display: flex;
  padding: 0 1rem;
}
.admincontractor-lcdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.admincontractor-lcdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.admincontractor-agreementdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.admincontractor-agreementdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.admincontractor-agreementdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.admincontractor-agreementdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.admincontractor-agreementdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.admincontractor-agreementdetails--grid__item-wrapper {
  display: flex;
  padding: 0 1rem;
}
.admincontractor-agreementdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.admincontractor-agreementdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.admincontractor-perfDetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.admincontractor-perfDetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.admincontractor-perfDetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.admincontractor-perfDetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.admincontractor-perfDetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.admincontractor-perfDetails--grid__item-wrapper {
  display: flex;
  padding: 0 1rem;
}
.admincontractor-perfDetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.admincontractor-perfDetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.admincontractor-descriptionmodal {
  width: clamp(min(70vw, 60rem), 60rem, 100vw);
  height: clamp(min(60vh, 40rem), 40rem, 100vw);
  overflow: auto;
}
.admincontractor-verifymodal {
  width: 40rem;
  height: -moz-max-content;
  height: max-content;
}
.admincontractor-verifymodal--textarea {
  height: 12.5rem;
}
.admincontractor-verifymodal--submit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  gap: 1rem;
}
.admincontractor-verifymodal--message {
  padding: 1rem 0;
  font-size: 1.3rem;
}
.admincontractor-verifymodal--message__warning {
  color: #961616;
}
.admincontractor-verifymodal--message__wrn {
  padding: 1rem 0;
}
.admincontractor-verifymodal--message__main {
  font-size: 2rem;
}
.admincontractor-verifymodal--reject {
  width: 50vw;
}
.admincontractor-verifymodal--reject__submit {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.subprojectcreate {
  width: 50vw !important;
  max-height: 80vh;
  overflow: auto;
  padding: 0.2rem;
}
.subprojectcreate-heading {
  color: #006699;
  margin: 1rem 0;
}
.subprojectcreate-actions {
  display: flex;
  justify-content: flex-end;
  row-gap: 1rem;
}
.subprojectcreate-loans {
  display: flex;
  -moz-column-gap: 2.2%;
       column-gap: 2.2%;
  row-gap: 2rem;
  flex-wrap: wrap;
}
.subprojectcreate-loans--item {
  width: 23.3%;
  border-radius: 0.4rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s linear;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-top: 0.5rem solid rgba(0, 0, 0, 0.15);
}
.subprojectcreate-loans--item_date {
  font-size: 1.2rem;
  color: rgba(68, 68, 68, 0.5333333333);
}
.subprojectcreate-loans--item_agency {
  color: #006699;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.subprojectcreate-loans--item_currency {
  font-size: 1.2rem;
  color: #000;
}
.subprojectcreate-loans--item_selected {
  border-top: 0.5rem solid #006699;
  border-left: 0.1rem solid #006699;
  border-right: 0.1rem solid #006699;
  border-bottom: 0.1rem solid #006699;
}

.subprojectcreateloan {
  width: 50vw !important;
  max-height: 80vh;
  overflow: auto;
  padding: 0.2rem;
}
.subprojectcreateloan-heading {
  color: #006699;
  margin: 1rem 0;
}
.subprojectcreateloan-actions {
  display: flex;
  justify-content: space-between;
  row-gap: 1rem;
  padding: 1.5rem 0;
}
.subprojectcreateloan-loans {
  display: flex;
  align-items: flex-start;
  -moz-column-gap: 2.2%;
       column-gap: 2.2%;
  row-gap: 2rem;
  flex-wrap: wrap;
}
.subprojectcreateloan-loans--item {
  width: 23.3%;
  border-radius: 0.4rem;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s linear;
  border-left: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-right: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.15);
  border-top: 0.5rem solid rgba(0, 0, 0, 0.15);
}
.subprojectcreateloan-loans--item_date {
  font-size: 1.2rem;
  color: rgba(68, 68, 68, 0.5333333333);
}
.subprojectcreateloan-loans--item_agency {
  color: #006699;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.subprojectcreateloan-loans--item_currency {
  font-size: 1.2rem;
  color: #000;
}
.subprojectcreateloan-loans--item_selected {
  border-top: 0.5rem solid #006699;
  border-left: 0.1rem solid #006699;
  border-right: 0.1rem solid #006699;
  border-bottom: 0.1rem solid #006699;
}

.report {
  display: flex;
  flex-direction: column;
}
.report-header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  padding: 1rem;
  border-radius: 0.4rem;
  background-color: #fff;
}
.report-header--title {
  display: flex;
  align-items: center;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.report-header--title__back {
  width: 3rem;
  height: 3rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #006699;
  border-radius: 0.3333333333rem;
  transition: all 0.3s ease-in-out;
}
.report-header--title__back:active {
  opacity: 0.8;
}
.report-header--title__back:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.report-header--title__name {
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
}
.report-header--action {
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.report-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.donorinfo {
  width: 100%;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}
.donorinfo-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006699;
  padding: 0.5rem;
  border-radius: 0.4rem;
}
.donorinfo-heading--title {
  color: #fff;
}
.donorinfo-heading--actions {
  width: 25%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
.donorinfo-heading--actions__wrapper {
  position: relative;
  width: 100%;
}
.donorinfo-heading--actions__export {
  background-color: #fff !important;
  color: #006699 !important;
}
.donorinfo-heading--actions__dropdown {
  position: absolute;
  top: 110%;
  max-height: 20rem;
  overflow: auto;
  width: 7.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.15rem 0;
  border-radius: 0.4rem;
}
.donorinfo-heading--actions__dropdown-item {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0.8rem 2rem;
  width: 100%;
  cursor: pointer;
}
.donorinfo-heading--actions__dropdown-item:hover {
  background-color: rgba(0, 102, 153, 0.25);
}
.donorinfo-wrapper {
  padding: 1rem;
  width: 100%;
  overflow: auto;
  border-radius: 0.4rem;
}
.donorinfo-wrapper--table {
  border-collapse: collapse;
}
.donorinfo-wrapper--table thead tr td {
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
  color: #006699;
  text-align: right !important;
}
.donorinfo-wrapper--table tbody tr:nth-child(2n) {
  background-color: rgba(107, 106, 106, 0.03);
}
.donorinfo-wrapper--table tbody tr td {
  font-weight: 400;
  font-size: 1.2rem;
  color: #6b6a6a;
  text-align: right !important;
}
.donorinfo-wrapper--table th,
.donorinfo-wrapper--table td {
  border: 0.1rem solid rgba(107, 106, 106, 0.3);
  padding: 1rem 0.5rem;
}

.ledger {
  width: 100%;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}
.ledger-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006699;
  padding: 0.5rem;
  border-radius: 0.4rem;
}
.ledger-heading--title {
  color: #fff;
}
.ledger-heading--actions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
.ledger-heading--actions__wrapper {
  width: 100%;
}
.ledger-heading--actions__input--wrapper {
  width: -moz-max-content;
  width: max-content;
  min-width: 40rem;
  position: relative;
  border: 1px solid rgba(0, 102, 153, 0.25);
}
.ledger-heading--actions__main {
  justify-content: center;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  height: -moz-max-content;
  height: max-content;
}
.ledger-heading--actions__export {
  background-color: #006699 !important;
  color: #fff !important;
  float: right;
  margin-bottom: 1rem;
}
.ledger-heading--actions__dropdown {
  position: absolute;
  top: 110%;
  width: -moz-max-content;
  width: max-content;
  justify-content: flex-start;
  border-radius: 0.4rem;
  width: max-content;
}
.ledger-heading--actions__dropdown--relative {
  background-color: transparent;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  width: -moz-max-content;
  width: max-content;
}
.ledger-heading--actions__dropdown-project {
  width: 30rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px;
  background-color: #fff;
  border-radius: 0.4rem;
  border-left: 0.5rem solid #006699;
  top: 0;
  left: 0;
  z-index: 9999;
  max-height: 40rem;
  overflow: auto;
}
.ledger-heading--actions__dropdown-project-item {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0.8rem 2rem;
  cursor: pointer;
}
.ledger-heading--actions__dropdown-project-item:hover {
  background-color: rgba(0, 102, 153, 0.25);
}
.ledger-heading--actions__dropdown-subproject {
  min-width: 30rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px;
  border-radius: 0.4rem;
  display: flex;
  border-left: 0.5rem solid #006699;
  top: 0;
  flex-direction: column;
  gap: 0.5rem;
  right: 0;
  z-index: 9999;
  max-height: 40rem;
  overflow: auto;
}
.ledger-heading--actions__dropdown-subproject--item {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0.75rem 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 25rem;
  overflow: auto;
}
.ledger-heading--actions__dropdown-subproject--item:hover {
  background-color: rgba(0, 102, 153, 0.25);
}
.ledger-wrapper {
  padding: 1rem;
  border-radius: 0.4rem;
  box-sizing: content-box;
  overflow: auto;
}
.ledger-wrapper--table {
  border-collapse: collapse;
  width: 100%;
  margin-right: 1rem;
}
.ledger-wrapper--table thead tr th {
  font-weight: 500;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #006699;
  text-align: right !important;
}
.ledger-wrapper--table thead tr th.center {
  text-align: center !important;
}
.ledger-wrapper--table thead tr td {
  font-weight: 400;
  font-size: 1.2rem;
  color: #6b6a6a;
  white-space: nowrap;
  text-align: right !important;
}
.ledger-wrapper--table tbody tr.primarycolor {
  background-color: rgba(0, 102, 153, 0.25) !important;
}
.ledger-wrapper--table tbody tr.rowopen {
  visibility: visible;
}
.ledger-wrapper--table tbody tr.rowclose {
  display: none;
}
.ledger-wrapper--table tbody tr:nth-child(2n) {
  background-color: rgba(107, 106, 106, 0.03);
}
.ledger-wrapper--table tbody tr th.cursor {
  background-color: rgba(107, 106, 106, 0.03);
  white-space: nowrap;
  cursor: pointer;
}
.ledger-wrapper--table tbody tr th.cursor::before {
  content: attr(data-cursor);
}
.ledger-wrapper--table tbody tr th {
  font-weight: 500;
  font-size: 1.3rem;
  white-space: nowrap;
  text-transform: uppercase;
  color: #006699;
  text-align: right !important;
}
.ledger-wrapper--table tbody tr td {
  text-align: right !important;
  font-weight: 400;
  font-size: 1.2rem;
  color: #6b6a6a;
  white-space: nowrap;
}
.ledger-wrapper--table th,
.ledger-wrapper--table td {
  border: 0.1rem solid rgba(107, 106, 106, 0.3);
  padding: 1rem 0.5rem;
}
.ledger-table--title {
  background-color: #c5d9e2 !important;
}

.directpayment {
  width: 100%;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
}
.directpayment-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006699;
  padding: 0.5rem;
  border-radius: 0.4rem;
}
.directpayment-heading--title {
  color: #fff;
}
.directpayment-heading--actions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
}
.directpayment-heading--actions__select {
  padding: 0.2rem 0;
  width: 15rem;
}
.directpayment-heading--actions__date {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 2rem;
  color: #fff;
}
.directpayment-heading--actions--main {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 25rem;
}
.directpayment-heading--actions__wrapper {
  position: relative;
  display: flex;
}
.directpayment-heading--actions__export {
  background-color: #fff !important;
  color: #006699 !important;
}
.directpayment-heading--actions__dropdown {
  position: absolute;
  top: 110%;
  max-height: 40rem;
  overflow: auto;
  width: 7.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.15rem 0;
  border-radius: 0.4rem;
}
.directpayment-heading--actions__dropdown-item {
  font-size: 1.5rem;
  font-weight: 300;
  padding: 0.8rem 2rem;
  width: 100%;
  cursor: pointer;
}
.directpayment-heading--actions__dropdown-item:hover {
  background-color: rgba(0, 102, 153, 0.25);
}
.directpayment-wrapper {
  padding: 1rem;
  width: 100%;
  overflow: auto;
  border-radius: 0.4rem;
}
.directpayment-wrapper--table {
  border-collapse: collapse;
}
.directpayment-wrapper--table thead tr th {
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
  white-space: nowrap;
  max-width: 30rem;
  text-transform: uppercase;
  color: #006699;
}
.directpayment-wrapper--table tbody tr:nth-child(2n) {
  background-color: rgba(107, 106, 106, 0.03);
}
.directpayment-wrapper--table tbody tr td {
  font-weight: 400;
  font-size: 1.2rem;
  color: #6b6a6a;
  white-space: nowrap;
  max-width: 30rem;
  overflow: hidden;
  text-align: right !important;
}
.directpayment-wrapper--table th,
.directpayment-wrapper--table td {
  border: 0.1rem solid rgba(107, 106, 106, 0.3);
  padding: 1rem 0.5rem;
}

.viewpmproject {
  padding: 0 1rem;
}
.viewpmproject-main--table {
  padding: 1rem 0;
}

.viewpmsubprojects {
  width: 50vw;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.viewpmsubprojects-fulldetails {
  overflow: auto;
  padding: 0rem 0.25rem;
}
.viewpmsubprojects-fulldetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.viewpmsubprojects-fulldetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem 0rem;
  font-weight: 300;
  border-bottom: 1px solid rgba(197, 217, 226, 0.5);
}
.viewpmsubprojects-fulldetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.viewpmsubprojects-fulldetails--grid__item-wrapper {
  display: flex;
}
.viewpmsubprojects-fulldetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 300;
}
.viewpmsubprojects-fulldetails--grid__item-value {
  padding-left: 1rem;
}
.viewpmsubprojects-submit {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.viewpmsubprojects-submit--approve {
  background-color: #1fb626 !important;
}
.viewpmsubprojects-submit--reject {
  background-color: #c71c1c !important;
}

.addremarkmodal {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0 0.2rem;
}
.addremarkmodal-action {
  display: flex;
  justify-content: flex-end;
}

.pmcontractor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pmcontractor-lcdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
}
.pmcontractor-lcdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.pmcontractor-lcdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.pmcontractor-lcdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.pmcontractor-lcdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.pmcontractor-lcdetails--grid__item-wrapper {
  display: flex;
}
.pmcontractor-lcdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.pmcontractor-lcdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.pmcontractor-agreementdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.pmcontractor-agreementdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.pmcontractor-agreementdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.pmcontractor-agreementdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.pmcontractor-agreementdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.pmcontractor-agreementdetails--grid__item-wrapper {
  display: flex;
}
.pmcontractor-agreementdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.pmcontractor-agreementdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.pmcontractor-perfDetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.pmcontractor-perfDetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.pmcontractor-perfDetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.pmcontractor-perfDetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.pmcontractor-perfDetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.pmcontractor-perfDetails--grid__item-wrapper {
  display: flex;
}
.pmcontractor-perfDetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.pmcontractor-perfDetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  padding-left: 1rem;
}
.pmcontractor-descriptionmodal {
  width: clamp(min(70vw, 60rem), 60rem, 100vw);
  height: clamp(min(60vh, 40rem), 40rem, 100vw);
  overflow: auto;
}
.pmcontractor-verifymodal {
  width: 40rem;
  height: -moz-max-content;
  height: max-content;
}
.pmcontractor-verifymodal--textarea {
  height: 12.5rem;
}
.pmcontractor-verifymodal--submit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  gap: 1rem;
}
.pmcontractor-verifymodal--message {
  padding: 1rem 0;
  font-size: 1.3rem;
}
.pmcontractor-verifymodal--message__warning {
  color: #961616;
}
.pmcontractor-verifymodal--message__wrn {
  padding: 1rem 0;
}
.pmcontractor-verifymodal--message__main {
  font-size: 2rem;
}
.pmcontractor-verifymodal--reject {
  width: 50vw;
}
.pmcontractor-verifymodal--reject__submit {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

.viewbillcustomduty {
  width: 70vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0 0.5rem;
}

.viewbilling {
  padding: 0 1rem;
}
.viewbilling-main--table {
  padding: 1rem 0;
}

.viewpmsubproject {
  padding: 0 1rem;
}
.viewpmsubproject-main--table {
  padding: 1rem 0;
}

.pmcloseproject {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.pmcloseproject-title {
  color: #6b6a6a;
}
.pmcloseproject-option {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.pmcloseproject-option > * {
  min-height: 2.25rem;
  min-width: 7rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 0;
  font-weight: 300;
  cursor: pointer;
  border-radius: 0.4rem;
}
.pmcloseproject-option--check {
  color: #fff;
  background-color: #006699;
}
.pmcloseproject-option--cross {
  background-color: white;
  color: #c71c1c;
  border: solid 1px #c71c1c;
}
.pmcloseproject-option--cross:hover {
  background-color: #f7bbbb;
  border: solid 1px #f7bbbb;
  color: #c71c1c;
}

.viewproject {
  padding: 0 1rem;
}
.viewproject-main--table {
  padding: 1rem 0;
}

.viewpmsubprojects {
  width: 50vw;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.viewpmsubprojects-fulldetails {
  overflow: auto;
  padding: 0rem 0.25rem;
}
.viewpmsubprojects-fulldetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.viewpmsubprojects-fulldetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem 0rem;
  font-weight: 300;
  border-bottom: 1px solid rgba(197, 217, 226, 0.5);
}
.viewpmsubprojects-fulldetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.viewpmsubprojects-fulldetails--grid__item-wrapper {
  display: flex;
}
.viewpmsubprojects-fulldetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 300;
}
.viewpmsubprojects-fulldetails--grid__item-value {
  padding-left: 1rem;
}
.viewpmsubprojects-submit {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.viewpmsubprojects-submit--approve {
  background-color: #1fb626 !important;
}
.viewpmsubprojects-submit--reject {
  background-color: #c71c1c !important;
}

.viewsubprojects {
  width: 50vw;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.viewsubprojects-remarks {
  border: 0.1rem dashed #c71c1c;
  border-radius: 0.4rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #c71c1c;
  background-color: #f7bbbb;
}
.viewsubprojects-form {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 1fr));
  gap: 1rem;
  padding: 0 0.3rem;
}
.viewsubprojects-submit {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 2rem 0;
}

.addbill {
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.addbill-heading {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.addbill-form {
  max-height: 80vh;
  overflow: auto;
  padding: 0.3rem;
  row-gap: 1rem;
}
.addbill-form--header {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  text-transform: uppercase;
  color: #006699;
  padding: 1rem 0;
}
.addbill-form--header select {
  padding: 0.8rem !important;
}
.addbill-form--header__button {
  display: flex;
  justify-content: flex-end;
}
.addbill-form--wrapper {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.addbill-form--wrapper__background {
  background-color: #c5d9e2;
  padding: 1rem;
  border-radius: 0.4rem;
  margin: 1rem 0;
}

.editbill {
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.editbill-heading {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.editbill-form {
  max-height: 80vh;
  overflow: auto;
  padding: 0.3rem;
  row-gap: 1rem;
}
.editbill-form--header {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  font-size: 1.5rem;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  text-transform: uppercase;
  color: #006699;
  padding: 1rem 0;
}
.editbill-form--header select {
  padding: 0.8rem !important;
}
.editbill-form--header__button {
  display: flex;
  justify-content: flex-end;
}
.editbill-form--wrapper {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.editbill-form--wrapper__background {
  background-color: #c5d9e2;
  padding: 1rem;
  border-radius: 0.4rem;
  margin: 1rem 0;
}

.contractor {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contractor-lcdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.contractor-lcdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.contractor-lcdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.contractor-lcdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.contractor-lcdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.contractor-lcdetails--grid__item-wrapper {
  display: flex;
  padding: 0 1rem;
}
.contractor-lcdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.contractor-lcdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.contractor-agreementdetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.contractor-agreementdetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.contractor-agreementdetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.contractor-agreementdetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.contractor-agreementdetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.contractor-agreementdetails--grid__item-wrapper {
  display: flex;
  padding: 0 1rem;
}
.contractor-agreementdetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.contractor-agreementdetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.contractor-perfDetails {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0rem 0.25rem;
}
.contractor-perfDetails--grid {
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}
.contractor-perfDetails--grid__item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  font-weight: 300;
}
.contractor-perfDetails--grid__item:nth-child(2n) {
  background-color: rgba(197, 217, 226, 0.4);
}
.contractor-perfDetails--grid__item-title {
  font-size: 2rem;
  font-weight: 400;
  color: #006699;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
.contractor-perfDetails--grid__item-wrapper {
  display: flex;
  padding: 0 1rem;
}
.contractor-perfDetails--grid__item-key {
  text-transform: capitalize;
  flex: 1;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
.contractor-perfDetails--grid__item-value {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}
.contractor-descriptionmodal {
  width: clamp(min(70vw, 60rem), 60rem, 100vw);
  height: clamp(min(60vh, 40rem), 40rem, 100vw);
  overflow: auto;
}

.viewsubproject {
  padding: 0 1rem;
}
.viewsubproject-main--table {
  padding: 1rem 0;
}

.closeproject {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.closeproject-title {
  color: #006699;
  text-transform: uppercase;
}
.closeproject-option {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.closeproject-option > * {
  min-height: 2.25rem;
  min-width: 7rem;
  text-transform: uppercase;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem 0;
  font-weight: 300;
  cursor: pointer;
  border-radius: 0.4rem;
}
.closeproject-option--check {
  color: #fff;
  background-color: #006699;
}
.closeproject-option--cross {
  background-color: white;
  color: #c71c1c;
  border: solid 1px #c71c1c;
}
.closeproject-option--cross:hover {
  background-color: #f7bbbb;
  border: solid 1px #f7bbbb;
  color: #c71c1c;
}

.insertbudget {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 2rem;
  padding: 0 0 1rem 0;
}
.insertbudget-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.6rem;
  color: #006699;
  gap: 1rem;
  font-weight: 500;
  grid-column: 1/-1;
}
.insertbudget-remarks {
  border: 0.1rem dashed #c71c1c;
  border-radius: 0.4rem;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: #c71c1c;
  background-color: #f7bbbb;
  margin-bottom: 1.5rem;
}
.insertbudget-wrapper {
  max-height: 90vh;
  overflow: auto;
  padding: 0 2rem;
  width: 78vw;
}
.insertbudget-first {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 2rem;
}
.insertbudget-last {
  display: flex;
  grid-column: 1/-1;
}
.insertbudget-submit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
}
.insertbudget-apg {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
.insertbudget-apg--main {
  background-color: #c5d9e2;
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  gap: 1rem;
  padding: 1rem;
}
.insertbudget-apg--remove {
  display: flex;
  padding: 1rem 0;
  justify-content: flex-end;
}
.insertbudget-apg--title {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 1rem 1rem 0;
  align-items: center;
  font-size: 1.6rem;
  color: #006699;
  gap: 1rem;
}
.insertbudget-apg--title__select {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 3.25rem;
}
.insertbudget-apg--title__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.insertbudget-apg--currency {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.insertbudget-files {
  margin-top: 1rem;
}
.insertbudget-currency {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 2rem;
}

.acbudget-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.button {
  height: 100% !important;
  background-color: #006699;
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 0.4rem;
  outline: none;
  display: flex;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.button i {
  font-size: 1.6rem;
}
.button-secondary {
  border: 0.1rem solid #006699 !important;
  color: #006699 !important;
  background-color: #fff !important;
  transition: all 0.4s ease;
}
.button-secondary:hover {
  color: #fff !important;
  background-color: #006699 !important;
}
.button:active {
  opacity: 0.8;
}

.smallbutton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  outline: 0px solid #006699;
  border: 0px solid #006699;
  min-width: 10rem;
  min-height: 2.35em;
  padding: 0.25rem 0.75rem;
  background-color: #006699;
  color: #fff;
  border-radius: 0.4rem;
  letter-spacing: 0.01em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.actionbutton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  all: unset;
  width: 3rem;
  height: 3rem;
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.actionbutton-primary {
  background-color: #c5d9e2;
  color: #006699;
}
.actionbutton-primary:hover {
  background-color: #006699;
  color: #fff;
}
.actionbutton-danger {
  background-color: #f7bbbb;
  color: #c71c1c;
}
.actionbutton-danger:hover {
  background-color: #c71c1c;
  color: #fff;
}

.modal {
  z-index: 2000;
  position: fixed;
  background-color: #fff;
  border-radius: 5px;
}
.modal-background {
  top: 0;
  left: 0;
  z-index: 2000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: rgba(68, 68, 68, 0.5333333333);
}
.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.modal-title.generic {
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}
.modal-title--main {
  color: #006699;
  font-size: 1.75rem;
  font-weight: 400;
}
.modal-title--main__handler {
  display: flex;
  gap: 0.5rem;
  padding: 0.2rem 0;
}
.modal-title--cross {
  font-size: 2rem;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5d9e2;
  color: #006699;
  border-radius: 0.4rem;
}
.modal-body {
  padding: 1rem;
}

.responsive {
  overflow-x: auto;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 8px;
  border-radius: 0.4rem;
}

.table {
  min-width: 100%;
  width: -moz-max-content;
  width: max-content;
  max-height: 100vh;
  text-align: center;
  border-collapse: collapse;
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
}
.table-thead {
  border: 0.3rem solid #006699;
  position: sticky;
  top: 0;
}
.table-thead--tr {
  height: 4rem;
  background-color: #006699;
}
.table-thead--tr__td {
  text-align: left !important;
  font-size: 1.3rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #fff;
  cursor: pointer;
  padding: 1.3rem 1rem;
}
.table-thead--tr__td-sort {
  padding: 0 0 0 0.5rem;
  font-size: 1.15rem;
}
.table-tbody {
  padding: 0;
}
.table-tbody--tr__td {
  padding: 1.4rem 1rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.08);
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
}
.table-tbody--tr.noData td {
  padding: 4rem !important;
  font-size: 1.6rem;
  font-weight: 300;
  font-style: italic;
  color: rgba(68, 68, 68, 0.5333333333);
  letter-spacing: -0.1rem;
  text-transform: uppercase;
}
.table-tbody--tr:last-child td {
  border-bottom: none !important;
}
.table-tbody--tr:hover {
  background-color: rgba(0, 102, 153, 0.1);
}
.table-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tablewrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.tablewrapper-title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.tablewrapper-title--search {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tablewrapper-title--search__wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.tablewrapper-title--search__input {
  width: 17.5rem;
  padding: 0.5rem;
  outline: none;
  border: none;
  border-bottom-left-radius: 0.2666666667rem;
  border-top-left-radius: 0.2666666667rem;
}
.tablewrapper-title--search__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  color: #006699;
  border-bottom-right-radius: 0.2666666667rem;
  border-top-right-radius: 0.2666666667rem;
}
.tablewrapper-title--select {
  height: 100%;
  padding: 0.6rem;
  display: flex;
  justify-content: center;
  background-color: white;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.7);
  border-radius: 0.4rem;
}
.tablewrapper-title--select > * {
  text-transform: capitalize;
}
.tablewrapper-pagination {
  height: 4rem;
  display: flex;
  justify-content: flex-end;
}
.tablewrapper-pagination--main {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.tablewrapper-pagination--main__left, .tablewrapper-pagination--main__right {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #006699;
  border-radius: 0.4rem;
  cursor: pointer;
}
.tablewrapper-pagination--main__left:active, .tablewrapper-pagination--main__right:active {
  opacity: 0.8;
}
.tablewrapper-pagination--main__left-disabled, .tablewrapper-pagination--main__right-disabled {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 102, 153, 0.6);
  border-radius: 0.4rem;
  cursor: pointer;
  opacity: 0.8;
}
.tablewrapper-pagination--main__left-disabled:active, .tablewrapper-pagination--main__right-disabled:active {
  opacity: 0.6;
}
.tablewrapper-pagination--main__page {
  width: 2rem;
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: rgba(68, 68, 68, 0.5333333333);
  cursor: default;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  position: relative;
  z-index: 0;
}
.input-label {
  font-size: 1.2rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.input-input {
  font-size: 1.4rem;
  font-weight: 300;
  width: 100%;
  padding: 0.9rem 0.75rem;
  outline: 1px solid rgba(142, 142, 142, 0.4666666667);
  border: none;
  border-radius: 0.2rem;
}
.input-input--wrapper {
  width: 100%;
  position: relative;
}
.input-input:focus {
  outline: 1px solid #006699;
}
.input-input--eye {
  font-size: 1.25rem;
  position: absolute;
  right: 0.5rem;
  top: 2.8rem;
}
.input-error {
  outline: 1px solid #c71c1c;
}
.input-error--text {
  color: #c71c1c;
  font-size: 1.2rem;
  font-weight: 300;
  text-transform: capitalize;
}
.input-radio {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 0;
}
.input-radio-label {
  font-size: 1.4rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.input-radio-input {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  outline: none;
  border: none;
  border: 1px solid rgba(142, 142, 142, 0.4666666667);
  border-radius: 0.2rem;
}
.input-radio-input:focus {
  border: 1px solid #006699;
}

.fileinput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px dashed rgba(142, 142, 142, 0.4666666667);
  background-color: rgba(0, 102, 153, 0.06);
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
}
.fileinput-main {
  padding: 2rem 0;
}
.fileinput-main--label {
  font-size: 5rem;
  cursor: pointer;
  opacity: 0.5;
  color: #006699;
}
.fileinput-main--input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fileinput-preview {
  position: relative;
  display: flex;
  gap: 2rem;
}
.fileinput-preview--noimage {
  color: rgba(68, 68, 68, 0.5333333333);
  font-style: italic;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: -0.1rem;
}
.fileinput-preview--images {
  height: 8rem;
  width: 8rem;
  background-color: #c5d9e2;
  position: relative;
  border-radius: 0.4rem;
  overflow: hidden;
}
.fileinput-preview--images__main {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.fileinput-preview--images__cross {
  background-color: #f7bbbb;
  color: #c71c1c;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
}
.fileinput-document {
  padding: 0.25rem 0;
}

.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  position: relative;
  z-index: 0;
  height: 100% !important;
}
.select label {
  font-size: 1.2rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.select label + select {
  padding: 0.9rem;
}
.select select {
  height: 100% !important;
  width: 100%;
  padding: 0 0.5rem;
  border-right: 0.5rem solid transparent !important;
  font-size: 1.3rem;
  font-weight: 300;
  outline: 1px solid rgba(142, 142, 142, 0.4666666667);
  border: none;
  border-radius: 0.2rem;
}
.select select:focus {
  outline: 1px solid #006699;
}
.select-error {
  outline: 1px solid #c71c1c !important;
}
.select-error--text {
  color: #c71c1c;
  font-size: 1.2rem;
}

.textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.textarea-label {
  font-size: 1.2rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.textarea-input {
  font-size: 1.25rem;
  letter-spacing: 0.06em;
  width: 100%;
  min-height: 10rem;
  padding: 0.75rem 0.75rem;
  outline: 1px solid rgba(142, 142, 142, 0.4666666667);
  border: none;
  border-radius: 0.2rem;
  resize: vertical;
}
.textarea-input:focus {
  outline: 1px solid #006699;
}
.textarea-error {
  outline: 1px solid rgb(212, 8, 8);
}
.textarea-error--text {
  color: rgba(212, 8, 8, 0.8);
  font-size: 1.2rem;
}

.inputdate {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.inputdate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inputdate-header--label {
  font-size: 1.2rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.inputdate-header--action {
  font-size: 1.4rem;
  font-weight: 300;
  outline: 1px solid rgba(142, 142, 142, 0.4666666667);
  border: none;
  border-radius: 0.2rem;
}
.inputdate-header--action:focus {
  outline: 1px solid #006699;
}
.inputdate-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.inputdate-display {
  font-size: 1.4rem;
  font-weight: 300;
  width: 100%;
  padding: 0.5rem 0.75rem;
  outline: 1px solid rgba(142, 142, 142, 0.4666666667);
  border: none;
  border-radius: 0.2rem;
}
.inputdate-display:focus {
  outline: 1px solid #006699;
}
.inputdate-error {
  outline: 1px solid #c71c1c;
}
.inputdate-error--text {
  color: #c71c1c;
  font-size: 1.2rem;
}
.inputdate-ad {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  opacity: 0;
}
.inputdate .calendar {
  position: absolute;
  z-index: 100 !important;
  width: 100%;
  top: 110%;
  left: 0%;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
  z-index: 0;
}
.checkbox-label {
  font-size: 1.4rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.checkbox-input {
  width: 2rem;
  height: 2rem;
  padding: 1rem;
  outline: none;
  border: none;
  border: 1px solid rgba(142, 142, 142, 0.4666666667);
  border-radius: 0.2rem;
}
.checkbox-input:focus {
  border: 1px solid #006699;
}

.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 102, 153, 0.125);
  width: 100%;
  height: 100%;
  font-size: 2.6rem;
  font-weight: 300;
  font-style: italic;
  color: rgba(68, 68, 68, 0.5333333333);
  letter-spacing: -0.1rem;
  text-transform: uppercase;
}

.loader {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  margin: 5rem auto;
  border: 0.8rem solid #006699;
  border-left-color: #c5d9e2;
  animation: load8 1.2s infinite linear;
}
.loader::after {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingmodal {
  top: 0;
  left: 0;
  z-index: 2000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: rgba(197, 217, 226, 0.5);
}
.loadingmodal-loader {
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
  margin: 5rem auto;
  border: 1rem solid #006699;
  border-left-color: #fff;
  animation: load8 1.2s infinite linear;
}
.loadingmodal-loader::after {
  border-radius: 50%;
  width: 8rem;
  height: 8rem;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.statusbar {
  width: -moz-max-content;
  width: max-content;
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  border-radius: 1.2rem;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}
.statusbar-active {
  background-color: #b0ecb3;
  color: #1fb626;
}
.statusbar-active::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  background-color: #1fb626;
  border-radius: 50%;
}
.statusbar-inactive {
  background-color: #ccc8c8;
  color: #6b6a6a;
}
.statusbar-inactive::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  background-color: #6b6a6a;
  border-radius: 50%;
}
.statusbar-completed {
  background-color: #b0ecb3;
  color: #1fb626;
}
.statusbar-completed::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  background-color: #1fb626;
  border-radius: 50%;
}
.statusbar-running {
  background-color: #fff8b5;
  color: #f1d900;
}
.statusbar-running::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  width: 0.8rem;
  height: 0.8rem;
  background-color: #f1d900;
  border-radius: 50%;
}

.view {
  width: -moz-max-content;
  width: max-content;
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  background-color: #006699;
  color: #fff;
}
.view-prefix {
  padding-right: 0.8rem;
}

.preview {
  overflow: hidden;
}
.preview-img {
  width: 5rem;
  height: 5rem;
  border-radius: 0.4rem;
  -o-object-fit: cover;
     object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 3px;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  position: relative;
  z-index: 0;
}
.input-label {
  font-size: 1.2rem;
  color: #006699;
  font-weight: 300;
  text-transform: uppercase;
}
.input-wrapper {
  display: flex;
  gap: 0.1rem;
  font-size: 1.4rem;
  font-weight: 300;
  width: 100%;
  outline: 1px solid rgba(142, 142, 142, 0.4666666667);
  border: none;
  border-radius: 0.2rem;
}
.input-wrapper:focus {
  outline: 1px solid #006699;
}
.input-wrapper--input:first-child {
  width: auto;
}
.input-wrapper--input {
  outline: none;
  font-size: 1.4rem;
  font-weight: 300;
  width: 100%;
  padding: 0.9rem 0.75rem;
  border: none;
  border-radius: 0.4rem;
}
.input-wrapper--input:focus {
  outline: 1px solid #006699;
}
.input-wrapper--eye {
  font-size: 1.25rem;
  position: absolute;
  right: 0.5rem;
  bottom: 1.25rem;
}
.input-error {
  outline: 1px solid #c71c1c;
}
.input-error--text {
  color: #c71c1c;
  font-size: 1.2rem;
}

.frame {
  display: flex;
}
.frame-body {
  flex: 1;
  padding: 1.5rem;
  width: 79% !important;
}

.calendar_back {
  position: absolute;
  top: 3%;
  left: 3%;
  right: 0;
  overflow: hidden;
  width: -moz-max-content;
  width: max-content;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff;
  border-radius: 0.4rem;
}
.calendar_back-heading {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.calendar_back-heading--actions {
  display: flex;
  gap: 0.5rem;
}
.calendar_back-heading--actions .select {
  width: 7rem !important;
}
.calendar_back-heading--actions .select-select {
  padding: 0.5rem 0;
  outline: 0.01rem solid #006699;
  border: none;
  color: #006699;
}
.calendar_back-heading--english {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #006699;
}
.calendar_back-week {
  display: grid;
  grid-template-columns: repeat(7, minmax(auto, 1fr));
  grid-row-gap: 0.1rem;
  grid-column-gap: 0.1rem;
  margin-bottom: 0.1rem;
}
.calendar_back-week__item {
  background-color: #006699;
  width: 4rem;
  height: 4rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 300;
}
.calendar_back-week__item:last-child {
  border: none;
}
.calendar_back-days {
  display: grid;
  grid-template-columns: repeat(7, minmax(auto, 1fr));
  grid-row-gap: 0.1rem;
  grid-column-gap: 0.1rem;
}
.calendar_back-days__item, .calendar_back-days__item-selected {
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  background-color: #c5d9e2;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 300;
  cursor: pointer;
  color: #006699;
}
.calendar_back-days__item:hover, .calendar_back-days__item-selected:hover {
  background-color: #006699;
  color: #fff;
}
.calendar_back-days__item-selected, .calendar_back-days__item-selected-selected {
  background-color: #006699;
  color: #fff;
}
.calendar_back-days__item.disabled, .calendar_back-days__item-selected.disabled {
  background-color: #ccc8c8 !important;
  color: #006699;
}
.calendar_back-days__item.disabled:hover, .calendar_back-days__item-selected.disabled:hover {
  background-color: #ccc8c8 !important;
  color: #006699;
}/*# sourceMappingURL=main.css.map */