@import "AddLoan/addloan";

.viewloan {
  padding: 0 1rem;

  &-main {
    &--table {
      padding: 1rem 0;
    }
  }
  &-donordetails {
    width: 40vw;
    max-height: 80vh;
    overflow: auto;
    padding: 0rem 0.25rem;
    &--grid {
      column-gap: 2rem;
      &__item {
        &:nth-child(2n) {
          background-color: rgba($color: $alphaPrimary, $alpha: 0.4);
        }
        display: flex;
        gap: 1rem;
        padding: 1rem;
        font-weight: 300;

        &-title {
          font-size: 2rem;
          font-weight: 400;
          color: $primary;
          text-transform: uppercase;
          padding-bottom: 2rem;
        }
        &-wrapper {
          display: flex;
        }
        &-key {
          text-transform: capitalize;
          flex: 1;
          font-weight: 400;
          color: rgba($color: $black, $alpha: 0.8);
        }
        &-value {
          color: rgba($color: $black, $alpha: 0.5);
          font-weight: 400;
          padding-left: 1rem;
        }
      }
    }
  }
  &-fulldetails {
    width: 40vw;
    max-height: 80vh;
    overflow: auto;
    padding: 0rem 0.25rem;
    &--grid {
      display: flex;
      justify-content: center;
      flex-direction: column;
      &__item {
        display: flex;
        gap: 1rem;
        padding: 0.5rem 0rem;
        font-weight: 300;

        border-bottom: 1px solid rgba($color: $alphaPrimary, $alpha: 0.5);
        &-title {
          font-size: 1.5rem;
          font-weight: 500;
          color: $primary;
          text-transform: uppercase;
          padding-bottom: 0.25rem;
          padding-top: 0.5rem;
        }
        &-desc {
          display: grid;
          // grid-template-columns: repeat(3, minmax(auto, 1fr));
          gap: 0.5rem;
          column-gap: 1.5rem;
          padding-bottom: 1rem;
        }
        &-wrapper {
          display: flex;
        }
        &-key {
          text-transform: capitalize;
          flex: 1;
          font-weight: 300;
        }
        &-value {
          padding-left: 1rem;
          // flex: 1;
        }
      }
    }
  }
}
