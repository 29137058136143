.sidebar {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem 1.5rem;
  position: sticky;
  top: 0;
  width: 19%;
  height: 100vh;
  overflow: auto;

  &.between {
    justify-content: space-between;
  }
  &.normal {
    gap: 2rem;
  }
  &.close {
    width: auto !important;
    .sidebar-action__create {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    &__image {
      width: 9rem;
      height: 9rem;
      object-fit: contain;
      &-small {
        width: 4.5rem !important;
        height: 4.5rem !important;
      }
    }
    &__text {
      text-align: center;
      text-transform: uppercase;
      color: $primary;
      font-weight: 700;
    }
  }
  &-menu {
    list-style: none;
    &--list {
      padding: 0.5rem;
      border-radius: $radius;
      margin: 0.5rem 0;
      text-transform: uppercase;
      &__link {
        width: 100%;
        color: $black;
        font-weight: 300;
        display: flex;
        align-items: center;
        column-gap: 1rem;
        font-size: 1.4rem;
        i {
          color: $alphaBlack;
          width: 3.5rem;
          height: 3.5rem;
          border-radius: calc($radius/2);

          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.6rem;
        }
      }
      &__active {
        background-color: $primary;
        &__link {
          color: $white;
          i {
            color: $primary;
            background-color: $white;
          }
        }
      }
    }
  }
  &-action {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    &__create-hover {
      background-color: $white !important;
      border: 0.1rem solid $primary !important;
      color: $primary !important;
      &:hover {
        background-color: $primary !important;
        color: $white !important;
      }
    }
    &__create {
      background-color: $primary;
      color: $white;
      padding: 1rem;
      border-radius: $radius;
      display: flex;
      column-gap: 0.5rem;
      font-weight: 300;
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
}
