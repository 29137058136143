.createnotice {
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-add {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &-create {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
