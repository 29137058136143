.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  position: relative;
  z-index: 0;
  height: 100% !important;
  label {
    font-size: 1.2rem;
    color: $primary;
    font-weight: 300;
    text-transform: uppercase;
  }
  label + select {
    padding: 0.9rem;
  }
  select {
    height: 100% !important;
    width: 100%;
    padding: 0 0.5rem;
    border-right: 0.5rem solid transparent !important;
    font-size: 1.3rem;
    font-weight: 300;
    outline: 1px solid $border;
    border: none;
    border-radius: calc($radius/2);
    &:focus {
      outline: 1px solid $primary;
    }
  }
  &-error {
    outline: 1px solid $error !important;
    &--text {
      color: $error;
      font-size: 1.2rem;
    }
  }
}
