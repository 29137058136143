.report {
  // padding: 1rem;
  display: flex;
  flex-direction: column;

  &-header {
    position: sticky;
    top: 0;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
    padding: 1rem;
    border-radius: $radius;
    background-color: $white;

    &--title {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      &__back {
        width: 3rem;
        height: 3rem;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        border-radius: calc($radius/1.2);
        transition: all 0.3s ease-in-out;
        &:active {
          opacity: 0.8;
        }
        &:hover {
          background-color: rgba($color: $black, $alpha: 0.07);
        }
      }
      &__name {
        font-weight: 400;
        color: rgba($color: $primary, $alpha: 1);
        text-transform: uppercase;
      }
    }
    &--action {
      display: flex;
      column-gap: 0.5rem;
      // height: 3.5rem;
    }
  }
  &-body {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 2rem;
    // padding: 0.5rem;
  }
}

.donorinfo {
  width: 100%;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    padding: 0.5rem;
    border-radius: $radius;
    &--title {
      color: $white;
    }
    &--actions {
      width: 25%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      &__wrapper {
        position: relative;
        width: 100%;
      }
      &__export {
        background-color: $white !important;
        color: $primary !important;
      }
      &__dropdown {
        position: absolute;
        top: 110%;
        max-height: 20rem;
        overflow: auto;
        width: 7.5rem;
        background-color: $white;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        box-shadow: $box-shadow;
        padding: 0.15rem 0;
        border-radius: $radius;
        &-item {
          font-size: 1.5rem;
          font-weight: 300;
          padding: 0.8rem 2rem;
          width: 100%;
          cursor: pointer;
          &:hover {
            background-color: rgba($color: $primary, $alpha: 0.25);
          }
        }
      }
    }
  }
  &-wrapper {
    padding: 1rem;
    width: 100%;
    overflow: auto;
    border-radius: $radius;
    &--table {
      border-collapse: collapse;

      thead {
        tr {
          td {
            font-weight: 500;
            font-size: 1.3rem;
            text-align: center;
            white-space: nowrap;
            text-transform: uppercase;
            color: $primary;
            text-align: right !important;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(2n) {
            background-color: rgba($color: $secondary, $alpha: 0.03);
          }
          td {
            font-weight: 400;
            font-size: 1.2rem;
            color: $secondary;
            text-align: right !important;
          }
        }
      }
      th,
      td {
        border: 0.1rem solid rgba($color: $secondary, $alpha: 0.3);
        padding: 1rem 0.5rem;
      }
    }
  }
}
.ledger {
  width: 100%;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    padding: 0.5rem;
    border-radius: $radius;
    &--title {
      color: $white;
    }
    &--actions {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;

      &__wrapper {
        width: 100%;
      }
      &__input--wrapper {
        width: max-content;
        min-width: 40rem;
        position: relative;
        border: 1px solid rgba($color: $primary, $alpha: 0.25);
      }
      &__main {
        justify-content: center;
        display: flex;
        width: 100%;
        gap: 0.5rem;
        height: max-content;
      }

      &__export {
        background-color: $primary !important;
        color: $white !important;
        float: right;
        margin-bottom: 1rem;
      }
      &__dropdown {
        position: absolute;
        top: 110%;

        width: max-content;

        justify-content: flex-start;

        border-radius: $radius;
        width: max-content;
        &--relative {
          background-color: transparent;
          display: flex;
          gap: 0.5rem;
          align-items: flex-start;
          width: max-content;
        }
        &-project {
          width: 30rem;
          box-shadow: $box-shadow-alt;
          background-color: $white;
          border-radius: $radius;
          border-left: 0.5rem solid $primary;

          top: 0;
          left: 0;
          z-index: 9999;
          max-height: 40rem;
          overflow: auto;
          &-item {
            font-size: 1.5rem;
            font-weight: 300;
            padding: 0.8rem 2rem;

            cursor: pointer;
            &:hover {
              background-color: rgba($color: $primary, $alpha: 0.25);
            }
          }
        }
        &-subproject {
          min-width: 30rem;
          background-color: $white;
          box-shadow: $box-shadow-alt;
          border-radius: $radius;
          display: flex;
          border-left: 0.5rem solid $primary;
          top: 0;
          flex-direction: column;
          gap: 0.5rem;
          right: 0;
          z-index: 9999;

          max-height: 40rem;
          overflow: auto;
          &--item {
            font-size: 1.5rem;
            font-weight: 300;
            padding: 0.75rem 1.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            width: 25rem;
            overflow: auto;
            &:hover {
              background-color: rgba($color: $primary, $alpha: 0.25);
            }
          }
        }
      }
    }
  }
  &-wrapper {
    padding: 1rem;

    border-radius: $radius;

    box-sizing: content-box;
    overflow: auto;
    &--table {
      border-collapse: collapse;
      width: 100%;
      margin-right: 1rem;

      thead {
        tr {
          th {
            font-weight: 500;
            font-size: 1.3rem;
            text-transform: uppercase;
            color: $primary;
            text-align: right !important;

            &.center {
              text-align: center !important;
            }
          }
          td {
            font-weight: 400;
            font-size: 1.2rem;
            color: $secondary;
            white-space: nowrap;
            text-align: right !important;
          }
        }
      }
      tbody {
        tr.primarycolor {
          background-color: rgba($color: $primary, $alpha: 0.25) !important;
        }
        tr.rowopen {
          visibility: visible;
        }
        tr.rowclose {
          display: none;
        }
        tr {
          &:nth-child(2n) {
            background-color: rgba($color: $secondary, $alpha: 0.03);
          }
          th.cursor {
            background-color: rgba($color: $secondary, $alpha: 0.03);
            white-space: nowrap;
            cursor: pointer;

            &::before {
              content: attr(data-cursor);
            }
          }
          th {
            font-weight: 500;
            font-size: 1.3rem;
            white-space: nowrap;
            text-transform: uppercase;
            color: $primary;
            text-align: right !important;
          }
          td {
            text-align: right !important;
            font-weight: 400;
            font-size: 1.2rem;
            color: $secondary;
            white-space: nowrap;
          }
        }
      }
      th,
      td {
        border: 0.1rem solid rgba($color: $secondary, $alpha: 0.3);
        padding: 1rem 0.5rem;
      }
    }
  }
  &-table {
    &--title {
      background-color: $alphaPrimary !important;
    }
  }
}
.directpayment {
  width: 100%;
  margin-top: 1rem;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;

  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    padding: 0.5rem;
    border-radius: $radius;
    &--title {
      color: $white;
    }
    &--actions {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;

      &__select {
        padding: 0.2rem 0;
        width: 15rem;
      }
      &__date {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-size: 2rem;
        color: $white;
      }
      &--main {
        display: flex;
        justify-content: center;
        gap: 1rem;
        width: 25rem;
      }
      &__wrapper {
        position: relative;
        display: flex;
      }
      &__export {
        background-color: $white !important;
        color: $primary !important;
      }
      &__dropdown {
        position: absolute;
        top: 110%;
        max-height: 40rem;
        overflow: auto;
        width: 7.5rem;
        background-color: $white;
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        box-shadow: $box-shadow;
        padding: 0.15rem 0;
        border-radius: $radius;
        &-item {
          font-size: 1.5rem;
          font-weight: 300;
          padding: 0.8rem 2rem;
          width: 100%;
          cursor: pointer;
          &:hover {
            background-color: rgba($color: $primary, $alpha: 0.25);
          }
        }
      }
    }
  }
  &-wrapper {
    padding: 1rem;
    width: 100%;
    overflow: auto;
    border-radius: $radius;
    &--table {
      border-collapse: collapse;

      thead {
        tr {
          th {
            font-weight: 500;
            font-size: 1.3rem;
            text-align: center;
            white-space: nowrap;
            max-width: 30rem;
            text-transform: uppercase;
            color: $primary;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(2n) {
            background-color: rgba($color: $secondary, $alpha: 0.03);
          }
          td {
            font-weight: 400;
            font-size: 1.2rem;
            color: $secondary;
            white-space: nowrap;
            max-width: 30rem;
            overflow: hidden;
            text-align: right !important;
          }
        }
      }
      th,
      td {
        border: 0.1rem solid rgba($color: $secondary, $alpha: 0.3);
        padding: 1rem 0.5rem;
      }
    }
  }
}
