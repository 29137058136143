.changepassword {
  min-width: max(30vw, 15rem);
  &-main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &--submit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0 0.5rem 0;
    }
  }
}
