.editbill {
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-heading {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  &-form {
    max-height: 80vh;
    overflow: auto;
    padding: 0.3rem;
    row-gap: 1rem;

    &--header {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-weight: 500;
      font-size: 1.5rem;
      column-gap: 0.5rem;
      text-transform: uppercase;
      color: $primary;
      padding: 1rem 0;
      select {
        padding: 0.8rem !important;
      }
      &__button {
        display: flex;
        justify-content: flex-end;
      }
    }
    &--wrapper {
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
      &__background {
        background-color: $alphaPrimary;
        padding: 1rem;
        border-radius: $radius;
        margin: 1rem 0;
      }
    }
  }
}
