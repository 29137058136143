.inputdate {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--label {
      font-size: 1.2rem;
      color: $primary;
      font-weight: 300;
      text-transform: uppercase;
    }
    &--action {
      font-size: 1.4rem;
      font-weight: 300;
      outline: 1px solid $border;
      border: none;
      border-radius: calc($radius/2);
      &:focus {
        outline: 1px solid $primary;
      }
    }
  }
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  &-display {
    font-size: 1.4rem;
    font-weight: 300;
    width: 100%;
    padding: 0.5rem 0.75rem;
    outline: 1px solid $border;
    border: none;
    border-radius: calc($radius/2);
    &:focus {
      outline: 1px solid $primary;
    }
  }
  &-error {
    outline: 1px solid $error;
    &--text {
      color: $error;
      font-size: 1.2rem;
    }
  }
  &-ad {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    opacity: 0;
  }
  & .calendar {
    position: absolute;
    z-index: 100 !important;
    width: 100%;
    top: 110%;
    left: 0%;
  }
}
