@import "./ViewCustomDuty/viewcustomduty";

.viewbilling {
  padding: 0 1rem;
  &-main {
    &--table {
      padding: 1rem 0;
    }
  }
}
