.addremarkmodal {
  width: 40vw;
  max-height: 80vh;
  overflow: auto;
  padding: 0 0.2rem;
  &-action {
    display: flex;
    justify-content: flex-end;
  }
}
