.contractormodal {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  align-items: center;
  height: 87vh;
  width: 80vw;
  overflow: auto;
  gap: 1rem;
  padding: 0 1rem;
  background-color: rgba($color: $alphaPrimary, $alpha: 0.05);

  &-service {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: $primary;
    text-transform: uppercase;
    &--main {
      display: grid;
      grid-template-columns: repeat(1, minmax(auto, 1fr));
      gap: 1rem;
    }
  }
  &-retention {
    grid-column: 1/-4;
  }
  &-performance,
  &-lc,
  &-title {
    grid-column: 1/-1;
    font-weight: 500;
    font-size: 1.5rem;
    color: $primary;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    gap: 0.2rem;
    row-gap: 1rem;
    &--main {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 1fr));
      gap: 1rem;
    }
  }
  &-image {
    grid-column: 1/-1;
  }
  &-submit {
    grid-column: 1/-1;
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0;
  }
  &-paymentterm {
    display: grid;
    grid-template-columns: repeat(1, minmax(auto, 1fr));

    grid-column: 1/-1;
  }
  &-remarks {
    grid-column: 1/-1;
    border: 0.1rem dashed $error;
    border-radius: $radius;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 300;
    color: $error;
    background-color: $alphaError;
  }
}
