.responsive {
  overflow-x: auto;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 8px;
  border-radius: $radius;
}
.table {
  min-width: 100%;
  width: max-content;
  max-height: 100vh;
  text-align: center;
  border-collapse: collapse;
  position: relative;
  border-radius: $radius;
  overflow: hidden;
  &-thead {
    border: 0.3rem solid $primary;
    position: sticky;
    top: 0;

    &--tr {
      height: 4rem;
      background-color: $primary;

      &__td {
        text-align: left !important;
        font-size: 1.3rem;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        color: $white;
        cursor: pointer;
        padding: 1.3rem 1rem;

        &-sort {
          padding: 0 0 0 0.5rem;
          font-size: 1.15rem;
        }
      }
    }
  }
  &-tbody {
    padding: 0;
    &--tr {
      &__td {
        padding: 1.4rem 1rem;
        border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.08);
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.02em;
        color: rgba($color: $black, $alpha: 0.7);

        text-align: left;
      }
      &.noData td {
        padding: 4rem !important;
        font-size: 1.6rem;
        font-weight: 300;
        font-style: italic;
        color: $alphaBlack;
        letter-spacing: -0.1rem;
        text-transform: uppercase;
      }
      &:last-child {
        td {
          border-bottom: none !important;
        }
      }
      &:hover {
        background-color: rgba($color: $primary, $alpha: 0.1);
      }
    }
  }
  &-loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tablewrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    &--search {
      border: 1px solid rgba($color: $black, $alpha: 0.3);
      border-radius: $radius;
      &__wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
      display: flex;
      align-items: center;
      justify-content: center;

      &__input {
        width: 17.5rem;
        padding: 0.5rem;
        outline: none;
        border: none;
        border-bottom-left-radius: calc($radius/1.5);
        border-top-left-radius: calc($radius/1.5);
      }
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        width: 3rem;
        color: $primary;
        border-bottom-right-radius: calc($radius/1.5);
        border-top-right-radius: calc($radius/1.5);
      }
    }
    &--select {
      height: 100%;
      padding: 0.6rem;
      display: flex;
      justify-content: center;
      background-color: white;
      outline: none;
      border: 1px solid rgba($color: $black, $alpha: 0.3);
      color: rgba($color: $black, $alpha: 0.7);
      border-radius: $radius;
      & > * {
        text-transform: capitalize;
      }
    }
  }
  &-pagination {
    height: 4rem;
    display: flex;
    justify-content: flex-end;
    &--main {
      display: flex;
      align-items: center;
      gap: 1rem;
      &__left,
      &__right {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $primary;
        border-radius: $radius;
        cursor: pointer;
        &:active {
          opacity: 0.8;
        }
      }
      &__left,
      &__right {
        &-disabled {
          width: 3rem;
          height: 3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;
          background-color: rgba($color: $primary, $alpha: 0.6);
          border-radius: $radius;
          cursor: pointer;
          opacity: 0.8;
          &:active {
            opacity: 0.6;
          }
        }
      }
      &__page {
        width: 2rem;
        display: flex;
        justify-content: center;
        font-size: 2rem;
        color: $alphaBlack;
        cursor: default;
      }
    }
  }
}
