.viewpmsubprojects {
  width: 50vw;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &-fulldetails {
    overflow: auto;
    padding: 0rem 0.25rem;
    &--grid {
      column-gap: 2rem;
      &__item {
        display: flex;
        gap: 1rem;
        padding: 1rem 0rem;
        font-weight: 300;

        border-bottom: 1px solid rgba($color: $alphaPrimary, $alpha: 0.5);
        &-title {
          font-size: 2rem;
          font-weight: 400;
          color: $primary;
          text-transform: uppercase;
          padding-bottom: 2rem;
        }
        &-wrapper {
          display: flex;
        }
        &-key {
          text-transform: capitalize;
          flex: 1;
          font-weight: 300;
        }
        &-value {
          padding-left: 1rem;
          // flex: 1;
        }
      }
    }
  }
  &-submit {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    &--approve {
      background-color: $success !important;
    }
    &--reject {
      background-color: $error !important;
    }
  }
}
