.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $primary, $alpha: 0.125);
  width: 100%;
  height: 100%;
  font-size: 2.6rem;
  font-weight: 300;
  font-style: italic;
  color: $alphaBlack;
  letter-spacing: -0.1rem;
  text-transform: uppercase;
}
