.dashboardpage {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-main {
    // padding: 0 0.5rem;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
.card {
  border-top: 0.4rem solid $primary;
  height: 15rem;
  width: 25rem;
  border-radius: $radius;
  background-color: $white;
  box-shadow: 1px 1px 8px rgba($color: $black, $alpha: 0.1);
  padding: 0.5rem;
  display: flex;
  position: relative;

  cursor: pointer;
  &:hover {
    background-color: #c5d9e2;
  }
  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem;
    &--icon {
      font-size: 3rem;
      color: $primary;
    }
    &--name {
      font-size: 1.25rem;
      font-weight: 300;
      color: $primary;
    }
    &--number {
      font-size: 10rem;
      font-weight: 900;
      color: rgba($color: $primary, $alpha: 0.7);
      position: absolute;
      right: 10%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
