@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

:root {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
}

$primary: #006699;
$alphaPrimary: #c5d9e2;
$white: #fff;
$black: #000;
$alphaBlack: #44444488;
$box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$box-shadow-alt: rgba(0, 0, 0, 0.1) 0px 2px 3px;
$box-shadow-reverse: rgba(0, 0, 0, 0.05) -0px -2px 3px;
$border: #8e8e8e77;
$radius: 0.4rem;
$error: #c71c1c;
$alphaError: #f7bbbb;
$success: #1fb626;
$alphaSuccess: #b0ecb3;
$secondary: #6b6a6a;
$alphaSecondary: #ccc8c8;
$warning: #f1d900;
$alphaWarning: #fff8b5;

//!Overrides
a {
  text-decoration: none;
  color: $black;
}
.notfound {
  border-radius: $radius;
  padding: 3rem 0;
  font-weight: 300;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  color: $alphaBlack;
  font-style: italic;
  letter-spacing: -0.1rem;
  &-bordered {
    border: 1px dashed $border;
  }
}
.error-remarks {
  border: 0.1rem dashed $error;
  border-radius: $radius;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  color: $error;
  background-color: $alphaError;
}

//!Custom

//!Overrides
::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.5rem !important;
  background: $alphaPrimary;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
