.viewsubprojects {
  // padding: 1rem 0;
  width: 50vw;
  max-height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &-remarks {
    border: 0.1rem dashed $error;
    border-radius: $radius;
    padding: 0 1rem;
    font-size: 1.4rem;
    font-weight: 300;
    color: $error;
    background-color: $alphaError;
  }
  &-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    gap: 1rem;
    padding: 0 0.3rem;
  }
  &-submit {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0 2rem 0;
  }
}
