.calendar {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
  border-radius: $radius;
  overflow: hidden;
  & * {
    font-size: 1.4rem;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: $primary;
    color: $white;
    &--close {
      cursor: pointer;
      &:active {
        opacity: 0.8;
      }
    }
  }
  &-controls {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    &--item {
      width: 30%;
    }
  }
  &-days {
    display: flex;
    justify-content: space-between;
    &--item {
      width: 14.28%;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.1rem solid rgba($color: $primary, $alpha: 0.06);
      background-color: $alphaPrimary;
      color: $primary;
    }
  }
}
