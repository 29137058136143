.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2rem;
  position: relative;
  z-index: 0;
  &-label {
    font-size: 1.2rem;
    color: $primary;
    font-weight: 300;
    text-transform: uppercase;
  }
  &-input {
    font-size: 1.4rem;
    font-weight: 300;
    width: 100%;
    padding: 0.9rem 0.75rem;
    outline: 1px solid $border;
    border: none;
    border-radius: calc($radius/2);
    &--wrapper {
      width: 100%;
      position: relative;
    }
    &:focus {
      outline: 1px solid $primary;
    }
    &--eye {
      font-size: 1.25rem;
      position: absolute;
      right: 0.5rem;
      top: 2.8rem;
    }
  }
  &-error {
    outline: 1px solid $error;

    &--text {
      color: $error;
      font-size: 1.2rem;
      font-weight: 300;
      text-transform: capitalize;
    }
  }
  &-radio {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    z-index: 0;
    &-label {
      font-size: 1.4rem;
      color: $primary;
      font-weight: 300;
      text-transform: uppercase;
    }
    &-input {
      width: 2rem;
      height: 2rem;
      padding: 1rem;
      outline: none;
      border: none;
      border: 1px solid $border;
      border-radius: calc($radius/2);
      &:focus {
        border: 1px solid $primary;
      }
    }
  }
}
