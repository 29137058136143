.insertbudget {
  display: grid;
  grid-template-columns: repeat(4, minmax(auto, 1fr));
  gap: 2rem;

  &-title {
    display: flex;
    justify-content: flex-start;

    align-items: center;
    font-size: 1.6rem;
    color: $primary;
    gap: 1rem;
    font-weight: 500;
    grid-column: 1/-1;
  }
  &-remarks {
    border: 0.1rem dashed $error;
    border-radius: $radius;
    padding: 1rem;
    font-size: 1.4rem;
    font-weight: 300;
    color: $error;
    background-color: $alphaError;
    margin-bottom: 1.5rem;
  }
  &-wrapper {
    max-height: 90vh;

    overflow: auto;
    padding: 0 2rem;
    width: 78vw;
  }
  padding: 0 0 1rem 0;
  &-first {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    gap: 2rem;
  }
  &-last {
    display: flex;
    grid-column: 1/-1;
  }
  &-submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 0;
  }
  &-apg {
    background-color: $white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
    &--main {
      background-color: $alphaPrimary;
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 1fr));
      gap: 1rem;
      padding: 1rem;
    }
    &--remove {
      display: flex;
      padding: 1rem 0;
      justify-content: flex-end;
    }
    &--title {
      display: flex;
      justify-content: flex-start;
      padding: 1rem 1rem 1rem 0;
      align-items: center;
      font-size: 1.6rem;
      color: $primary;
      gap: 1rem;

      &__select {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        height: 3.25rem;
      }
      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &--currency {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
  &-files {
    margin-top: 1rem;
  }
  &-currency {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(4, minmax(auto, 1fr));
    gap: 2rem;
  }
}
