.fileinput {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px dashed $border;
  background-color: rgba($color: $primary, $alpha: 0.06);
  border-radius: $radius;
  cursor: pointer;
  position: relative;
  &-main {
    padding: 2rem 0;
    &--label {
      font-size: 5rem;
      cursor: pointer;
      opacity: 0.5;
      color: $primary;
    }
    &--input {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &-preview {
    position: relative;
    display: flex;
    gap: 2rem;
    &--noimage {
      color: $alphaBlack;
      font-style: italic;
      font-weight: 300;
      font-size: 1.4rem;
      text-transform: uppercase;
      letter-spacing: -0.1rem;
    }
    &--images {
      height: 8rem;
      width: 8rem;
      background-color: $alphaPrimary;
      position: relative;
      border-radius: $radius;
      overflow: hidden;
      &__main {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
      &__cross {
        background-color: $alphaError;
        color: $error;
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        height: 2rem;
        width: 2rem;
      }
    }
  }
  &-document{
    padding: .25rem 0;
  }
}
