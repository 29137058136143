.textarea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  &-label {
    font-size: 1.2rem;
    color: $primary;
    font-weight: 300;
    text-transform: uppercase;
  }
  &-input {
    font-size: 1.25rem;
    letter-spacing: 0.06em;
    width: 100%;
    min-height: 10rem;
    padding: 0.75rem 0.75rem;
    outline: 1px solid $border;
    border: none;
    border-radius: calc($radius/2);
    resize: vertical;
    &:focus {
      outline: 1px solid $primary;
    }
  }
  &-error {
    outline: 1px solid rgb(212, 8, 8);
    &--text {
      color: rgba(212, 8, 8, 0.8);
      font-size: 1.2rem;
    }
  }
}
