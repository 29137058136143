.profilemenu {
  position: absolute;
  top: 150%;
  right: -5%;
  color: $primary;
  background-color: $white;
  box-shadow: $box-shadow;
  width: max-content;
  border-radius: $radius;
  overflow: hidden;
  z-index: 5;
  padding: 0.4rem 0;
  min-height: 5rem;
  min-width: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0 1.25rem;
    &--name {
      font-weight: 300;
      font-size: 1.4rem;
      padding: 1.2rem;
    }
    & > * {
      word-break: keep-all;
    }
    &:hover {
      background-color: $alphaPrimary;
    }
    &:last-child {
      color: $error;
      &:hover {
        background-color: $alphaError;
      }
    }
  }
}
