.calendar_back {
  position: absolute;
  top: 3%;
  left: 3%;
  right: 0;
  overflow: hidden;
  width: max-content;
  z-index: 100;
  box-shadow: $box-shadow;
  background-color: $white;
  border-radius: $radius;

  &-heading {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    &--actions {
      display: flex;
      gap: 0.5rem;
      .select {
        width: 7rem !important;
        &-select {
          padding: 0.5rem 0;
          outline: 0.01rem solid $primary;
          border: none;
          color: $primary;
        }
      }
    }
    &--english {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      color: $primary;
    }
  }
  &-week {
    display: grid;
    grid-template-columns: repeat(7, minmax(auto, 1fr));
    grid-row-gap: 0.1rem;
    grid-column-gap: 0.1rem;
    margin-bottom: 0.1rem;
    &__item {
      background-color: $primary;
      width: 4rem;
      height: 4rem;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 300;
      &:last-child {
        border: none;
      }
    }
  }
  &-days {
    display: grid;
    grid-template-columns: repeat(7, minmax(auto, 1fr));
    grid-row-gap: 0.1rem;
    grid-column-gap: 0.1rem;
    &__item,
    &__item-selected {
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      background-color: $alphaPrimary;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 300;
      cursor: pointer;
      color: $primary;

      &:hover {
        background-color: $primary;
        color: $white;
      }
      &-selected {
        background-color: $primary;
        color: $white;
      }
      &.disabled {
        background-color: $alphaSecondary !important;
        color: $primary;
        &:hover {
          background-color: $alphaSecondary !important;
          color: $primary;
        }
      }
    }
  }
}
