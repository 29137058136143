//!Components
@import "default";
@import "../../Components/default";
@import "../../Pages/default";
@import "../../UI/default";
.frame {
  display: flex;
  &-body {
    flex: 1;
    padding: 1.5rem;
    width: 79% !important;
  }
}

@import "../../Components/Calendar_Back/calendar_back";
