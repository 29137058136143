@import "./CreateNotice/createnotice";
@import "./EditNotice/editnotice";
@import "./Description/descriptionmodal";
.notice {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem;
  &-main {
    gap: 2rem;

    &--number {
      flex: 0.275;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
      border-radius: 0.4rem;
      height: max-content;
      overflow: hidden;
      &__title {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 300;
        letter-spacing: 0.02em;
        background-color: $primary;
        border-top-right-radius: 0.4rem;
        border-top-left-radius: 0.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0.75rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        & > * {
          padding: 0.5rem;
        }
        &-icon {
          background-color: #fff;
          color: $primary;
          height: 80%;
          font-size: 1.75rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 3rem;
          height: 3rem;
          border-radius: 0.4rem;
        }
      }
      &__description {
        display: flex;
        flex-direction: column;
        & > * {
          font-size: 1.4rem;
          font-weight: 300;
        }
        & > *:not(:last-child) {
          border-bottom: 0.1rem solid rgba(0, 0, 0, 0.07);
        }
      }
    }
    &--description {
      flex: 1;
      border-radius: 0.4rem;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 0.02em;
        background-color: $primary;
        border-top-right-radius: 0.4rem;
        border-top-left-radius: 0.4rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        color: #fff;
        padding: 0.49rem 1rem;
        font-size: 1.5rem;
        font-weight: 300;
        &-button {
          background-color: #fff;
          background-color: $primary;
        }
        &-action {
          display: flex;
          gap: 1rem;
          &--edit {
            background-color: #fff;
            color: $primary;
            width: 3rem;
            height: 3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.2rem;
            font-size: 1.75rem;
            cursor: pointer;
          }
        }
      }
      &__main {
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        letter-spacing: 0.1em;
        &-update {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 1rem 0;
        }
        &-show {
          padding: 1rem 0;
        }
      }
    }
    &--notice {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      &.active {
        color: $primary;
      }
      & > * {
        padding: 1.5rem 1rem;
      }
      &__name {
        width: 30rem !important;
      }
      &__action {
        background-color: rgba(199, 28, 28, 0.5);
        height: 100%;

        right: 0;
        width: 3.5rem;
        align-items: center;
        justify-content: center;
        display: none;
        :hover > & {
          display: flex;
        }
      }
    }
  }
}
