.view {
  width: max-content;
  font-size: 1.2rem;
  padding: 0.5rem;
  border-radius: calc($radius);
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;

  background-color: $primary;
  color: $white;
  &-prefix {
    padding-right: 0.8rem;
  }
}
