.button {
  height: 100% !important;
  background-color: $primary;
  color: $white;
  padding: 1rem;
  border: none;
  border-radius: $radius;
  outline: none;
  display: flex;
  column-gap: 0.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  i {
    font-size: 1.6rem;
  }

  &-secondary {
    border: 0.1rem solid $primary !important;
    color: $primary !important;
    background-color: $white !important;
    transition: all 0.4s ease;
    &:hover {
      color: $white !important;
      background-color: $primary !important;
    }
  }

  &:active {
    opacity: 0.8;
  }
}
.smallbutton {
  appearance: none;
  all: unset;
  outline: 0px solid $primary;
  border: 0px solid $primary;
  min-width: 10rem;
  min-height: 2.35em;
  padding: 0.25rem 0.75rem;

  background-color: $primary;
  color: $white;
  border-radius: $radius;

  letter-spacing: 0.01em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.actionbutton {
  appearance: none;
  all: unset;
  width: 3rem;
  height: 3rem;
  border-radius: $radius;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;

  &-primary {
    background-color: $alphaPrimary;
    color: $primary;
    &:hover {
      background-color: $primary;
      color: $white;
    }
  }
  &-danger {
    background-color: $alphaError;
    color: $error;
    &:hover {
      background-color: $error;
      color: $white;
    }
  }
}
