.modal {
  z-index: 2000;
  position: fixed;
  background-color: $white;
  border-radius: 5px;
  &-background {
    top: 0;
    left: 0;
    z-index: 2000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
    background-color: $alphaBlack;
  }
  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    &.generic {
      border-bottom: 0.1rem solid rgba($color: $black, $alpha: 0.1);
    }
    &--main {
      color: $primary;
      font-size: 1.75rem;
      font-weight: 400;
      &__handler {
        display: flex;
        gap: 0.5rem;
        padding: 0.2rem 0;
      }
    }
    &--cross {
      font-size: 2rem;
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $alphaPrimary;
      color: $primary;
      border-radius: $radius;
    }
  }
  &-body {
    padding: 1rem;
  }
}
