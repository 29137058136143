@import "./PreInformation/viewpreinformation";
@import "./UpdatePreInformation/viewpreinformation";
@import "./Billing/billing";
@import "./Contractor/contractor";
.viewsubproject {
  padding: 0 1rem;
  &-main {
    &--table {
      padding: 1rem 0;
    }
  }
}
.closeproject {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-title {
    color: $primary;
    text-transform: uppercase;
  }
  &-option {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    & > * {
      min-height: 2.25rem;
      min-width: 7rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.7rem 0;
      font-weight: 300;
      cursor: pointer;
      border-radius: $radius;
    }
    &--check {
      color: #fff;
      background-color: $primary;
    }
    &--cross {
      background-color: white;
      color: $error;
      border: solid 1px $error;
      &:hover {
        background-color: $alphaError;
        border: solid 1px $alphaError;
        color: $error;
      }
    }
  }
}
