.filesmodal {
  width: 60vw;
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &-nodata {
    width: 60vw;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &-main {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    &--item {
      width: 15rem;
      height: 15rem;
      border: 1px solid #0066991c;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.13) 0rem 0.3rem 0.8rem;
      &__wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 5px;
      }
      &__label {
        padding: 0.5rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &__icon {
        font-size: 10rem;
      }
      &__edit {
        background-color: rgba(255, 0, 0, 0.521);
        color: white;
        position: absolute;
        right: 0rem;
        top: 0rem;
        width: 4rem;
        height: 4rem;
        display: grid;
        place-items: center;
        display: none;
        cursor: pointer;
        :hover > & {
          display: grid;
        }
      }
    }
  }
}
