@import './LoginAccountChief/loginaccountchief';
@import './LoginAdmin/loginadmin';
@import './LoginPM/loginpm';

.login {
  height: 100vh;
  height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
  display: flex;
  align-items: center;
  justify-content: center;
  &-main {
    width: 60rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    &--title {
      display: flex;
      align-items: center;

      gap: 4rem;
      &__logo {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        overflow: hidden;
        & > * {
          object-fit: cover;
        }
      }
      &__main {
        flex: 1.05;
        color: $primary;
        text-align: center;
        font-weight: 500;
        line-height: 2.5rem;
        font-size: 2.1rem;
      }
    }
    &--form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      &__title {
        text-transform: uppercase;
        letter-spacing: 0.02em;
        color: $primary;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }
}
