.preview {
  overflow: hidden;
  &-img {
    width: 5rem;
    height: 5rem;
    border-radius: $radius;
    object-fit: cover;
    box-shadow: $box-shadow-alt;
  }
}
