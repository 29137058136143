.addpm {
  &-main {
    max-height: 80vh;
    padding: 0 1rem;
    width: 60vw;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
    &--single {
      display: grid;
      grid-column: 1/-1;
    }
    &--submit {
      grid-column: 1/-1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
