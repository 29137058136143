.changepassword {
  width: 10rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .input {
    margin-bottom: 1rem;
  }
  .button {
    display: flex;
    justify-content: center;
  }
}
